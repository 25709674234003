import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import {
  selectSelectedTopicVideo,
  setVideoToBeUploaded,
} from "../../../../redux/Guides";
import { showMessage } from "../../../../redux/alertUserMessage";

export default function UploadVideo() {
  const [videoUrl, setVideoUrl] = useState<string>("");
  // const [videoFile, setVideoFile] = useState<File | null>(null);
  const dispatch = useAppDispatch();
  const selectedVideo = useAppSelector(selectSelectedTopicVideo);

  useEffect(() => {
    if (selectedVideo && selectedVideo?.video?.url) {
      setVideoUrl(selectedVideo.video.url);
    }
  }, [selectedVideo]);

  const handleSelectVideo = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("click");
    const { files } = event.target;

    if (!files) return;

    if (files) {
      const file = files[0];
      // setVideoFile(file);
      let tempVideoUrl = URL.createObjectURL(file);

      setVideoUrl(tempVideoUrl);
      dispatch(setVideoToBeUploaded(file));
    }
  };

  return (
    <Form className="d-flex flex-column">
      <Form.Label>Upload Video</Form.Label>

      {videoUrl ? (
        <video style={{maxHeight: 370}} className="mw-100 mb-3" controls muted>
          <source src={videoUrl} type="video/mp4" />
        </video>
      ) : null}

      <Form.Group className="mb-3" controlId="formFileSm">
        <Form.Control
          type="file"
          accept="video/mp4"
          onChange={handleSelectVideo}
        />
      </Form.Group>
    </Form>
  );
}
