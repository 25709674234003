import { createGlobalStyle } from "styled-components";
import variables from "./variables";

export default createGlobalStyle`
html {
    background-color: ${variables.reelerGrey};
}
.link {
    text-decoration: none;
    color: white;
    &:hover {
        color: ${variables.reeler}
    }
}
.linkLogOut {
    text-decoration: none;
    &:hover {
        color: darkgray;
      }
}
#root {
    background-color: ${variables.reelerGrey};
    // Commented out margin top cuz of alert message
    // margin-top: 1rem;
}
.App {
    text-align: center;
  }
  
  .btn-link {
    font-weight: 400 !important;
    color: ${variables.reelerIcon} !important;
    text-decoration: none !important;
  }
  
  .btn-facebook {
    color: #fff;
    background-color: #1877f2;
    border: none !important;
  }
  .btn-main {
    color: ${variables.reelerBlack} !important;
    background-color: ${variables.reelerLight} !important;
    border: ${variables.reeler} !important;
  }
  .btn-delete {
    color: #fff !important;
    background-color: ${variables.reelerDanger}!important;
    border: ${variables.reelerDanger} !important;
  }
  .btn-delete:hover {
    color: #fff !important;
    background-color: ${variables.reelerDangerHover} !important;
    border: ${variables.reelerDanger} !important;
  }
  .btn-edit {
    color: #fff !important;
    background-color: ${variables.reelerEdit} !important;
    border: ${variables.reelerEdit} !important;
  }
  .btn-edit:hover {
    color: #fff !important;
    background-color: ${variables.reelerEditHover} !important;
    border: ${variables.reelerEdit} !important;
  }
  .btn-secondary {
    color: ${variables.reelerTitle} !important;
    background-color: #fff !important;
    border: 1px solid ${variables.reelerTitle} !important;
  }
  .btn-secondary:hover {
    color: ${variables.reelerTitle} !important;
    background-color: ${variables.reelerGreyDark} !important;
    border: 1px solid ${variables.reelerTitle} !important;
  }
  .btn-secondary:disabled {
    color: ${variables.reelerGreyDark} !important;
    background-color: transparent !important;
    border: 1px solid ${variables.reelerGreyDark} !important;
  }
  .btn-main:hover {
    background-color: ${variables.reeler} !important;
    border-color: ${variables.reeler} !important;
  }
  .btn-main:focus {
    border-color: ${variables.reelerLight} !important;
    box-shadow: 0 0 0 0.2rem ${variables.reelerO25} !important;
  }
  .btn-main:disabled {
    color: ${variables.reelerBlack} !important;
    background-color: ${variables.reelerLight} !important;
    border: ${variables.reelerBlack} !important;
  }
  .btn-main:hover:disabled {
    color: ${variables.reelerBlack} !important;
    background-color: ${variables.reelerLight}!important;
    border: ${variables.reelerBlack} !important;
  }
  
  .logo {
    color: ${variables.reeler} !important;
    font-family: "Changa One", cursive;
  }
  
  .logo-link {
    color: ${variables.reelerLink} !important;
    font-family: "Changa One", cursive;
  }
  
  .form-control {
    color: ${variables.reelerTitle}
  }
  
  .clickable {
    text-decoration: underline;
    cursor: pointer;
    color: ${variables.reelerTitle};
  }
  
  .form-control:focus {
    color: ${variables.reelerTitle} !important;
    border-color: ${variables.reelerLight} !important;
    box-shadow: 0 0 0 0.2rem ${variables.reelerO25} !important;
  }
  
  .form-label {
    color: ${variables.reelerTitle} !important;
    font-weight: 500 !important;
  }
  
  /* Card */
  .card-title {
    font-size: 1rem;
  }
  
  .active-main-nav {
    color: ${variables.reelerLight};
    border-bottom: 2px ${variables.reeler} solid;
    text-decoration: none;
    &:hover {
      color: ${variables.reeler};
    }
  }
  
  .nav_background {
    background: #fff;
    border-bottom: ${variables.reelerGreyDark} solid 1px;
  }
  
  .dropdown-menu {
    a:focus {
      background-color: ${variables.reeler};
      color: ${variables.reelerBlack};
    }
  }
  .table-link {
    color: ${variables.reelerBlack};
    
    &:hover {
      color: ${variables.reelerLink};
      cursor: pointer;
    }
  }
  .redDate {
    color: ${variables.reelerDanger};
  }
  .yellowDate {
    color: ${variables.reelerWarning};
  }
  .pointLink {
    cursor: pointer;
  }

  .thumbnail {
    padding: 50%; 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 
  }


  .nav-tabs .nav-link {
    color: ${variables.reelerGrayDarker} 
  }

  .nav-tabs .nav-link.active{
    color: ${variables.reeler} 
  }

`;
