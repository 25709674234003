import React from "react"
import ReelerButton from "../../../../components/commons/ReelerButton"
import { Dropdown, Form } from "react-bootstrap"
import { useAppSelector } from "../../../../redux/hooks"
import {
  selectSelectedTopic,
  updateTopic,
  updateTopicsWithSelectedTopic,
} from "../../../../redux/Guides"
import { useDispatch } from "react-redux"
import { GuideTopic } from "../../../../types/GuideTopic"
import { updateGuideInDB } from "../../../../services/GuideServices"
import { showMessage } from "../../../../redux/alertUserMessage"

const GuideTopicInfo: React.FC = () => {
  const selectedTopic = useAppSelector(selectSelectedTopic)
  const dispatch = useDispatch()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    dispatch(
      updateTopic({
        ...selectedTopic,
        [name]: value,
      } as GuideTopic)
    )
  }

  const handlePublish = (published: boolean) => {
    dispatch(
      updateTopic({
        ...selectedTopic,
        published: published,
      } as GuideTopic)
    )
  }

  const handleSave = () => {
    if (selectedTopic?.id) {
      // Save to database
      updateGuideInDB(selectedTopic)
        .then(() => dispatch(updateTopicsWithSelectedTopic()))
        .then(() => console.log("Guide saved!"))
        dispatch(showMessage({
          message: "Saved",
          variant: "success",
        }))
    }
  }

  return (
    <>
      <div className="d-flex w-100 justify-content-center">
        <Form className="m-5 w-50">
          <Form.Group className="mb-3" controlId="title">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Add a topic title"
              value={selectedTopic?.title}
              name="title"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={10}
              name="description"
              value={selectedTopic?.description}
              onChange={handleChange}
            />
          </Form.Group>
          <div className="d-flex justify-content-between">
            <ReelerButton text="Save" dispatch={() => handleSave()} />
            <Dropdown className="rounded">
              <Dropdown.Toggle
                variant={selectedTopic?.published ? "success" : "danger"}
                id="dropdown-basic"
                size="sm"
                style={{ padding: "7px 12px" }}
              >
                {selectedTopic?.published ? "Published" : "Hidden"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handlePublish(false)}>
                  Hidden
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handlePublish(true)}>
                  Published
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Form>
      </div>
    </>
  )
}

export default GuideTopicInfo
