import React from "react";

const NoContent = ({ text = "No content found." }) => (
  <div className="pt-5 d-flex flex-column justify-content-center align-items-center">
    <img
      className="mb-4"
      width="200px"
      src="../../no_content_women_dog.svg"
      alt=""
    />
    <p>{text}</p>
  </div>
);

export default NoContent;
