import React, { useState, useEffect } from "react"
import { Spinner } from "react-bootstrap"
import { InfoCard } from "../../../../components/commons/infocard/InfoCard"
import { selectActiveAccount } from "../../../../redux/activeAccount"
import { getTotalNumberOfAssetsForAccount } from "../../../../services/getTotalNumberOfAssetsForAccount"
import { useAppSelector } from "../../../../redux/hooks"

export const TotalAssets = () => {
  const activeAccount = useAppSelector(selectActiveAccount)
  const [loading, setLoading] = useState<boolean>(true)
  const [totalAssets, setTotalAssets] = useState<number>(0)

  useEffect(() => {
    if (activeAccount) {
      getTotalNumberOfAssetsForAccount(activeAccount.id).then(res => {
        if (res?.data?.noOfAssets) {
          setTotalAssets(res?.data?.noOfAssets)
          setLoading(false)
        } else {
          setTotalAssets(0)
          setLoading(false)
        }
      })
    }
  }, [activeAccount])

  return loading ? (
    <InfoCard
      label={"Assets"}
      children={<Spinner size="sm" animation="border" />}
    />
  ) : (
    <InfoCard label={"Assets"} kpi={totalAssets} />
  )
}

export default TotalAssets
