import styled from "styled-components";
import variables from "../../../styles/variables";

type AlertProps = {
  variant: string;
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 1rem 0;
  text-align: center;
`;

export const Alert = styled.div<AlertProps>`
  width: 100%;
  justify-content: center;
  padding: 1em;
  color: ${variables.reelerBlack};
  border-radius: 0.25rem;
  background-color: ${(props) =>
    props.variant === variables.reeler
      ? variables.reelerLight
      : variables.reelerDangerLight};
  border: 1px solid ${(props) => props.variant};
`;
