import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import { auth } from "../firebase";
import { User } from "firebase/auth";
import { logout } from "./AuthFunctions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { updateDetails } from "../redux/auth";
import { dataState, ICampaigns, IKpis, updateData } from "../redux/data";
import { getData } from "../services/getData";
import { getCampaigns } from "../services/getCampaigns";
import getUserDetails from "../services/getUserDetails";
import { IUserDetails } from "../models/IUserDetails";
import { getCount } from "../services/getCount";
import { getKpis } from "../services/getKpis";

interface IChildren {
  children: JSX.Element | JSX.Element[];
}

export const AuthContext = createContext<User | null>(null);

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }: IChildren) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const authRedux = useAppSelector((state) => state.auth);
  let dataObj: dataState = {
    data: {
      accounts: [],
      campaigns: [],
      users: [],
      feeds: [],
      noOfAssetsLastMonth: 0,
      noOfAssetsYesterday: 0,
      kpis: [],
    },
  };

  const logoutFailedLogin = useCallback(() => {
    logout();
    dispatch(
      updateDetails({
        user: {
          id: "",
          role: "",
          email: "",
        },
        msg: "Failed to login",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (currentUser) {
      const getUser = async () => {
        try {
          const promise = await getUserDetails(currentUser.uid);
          if (promise && promise) {
            dispatch(
              updateDetails({
                user: {
                  id: promise.id,
                  role: promise.role,
                  email: promise.email,
                } as IUserDetails,
                msg: "",
              })
            );
          } else {
            logoutFailedLogin();
          }
        } catch (err) {
          logoutFailedLogin();
          console.log("Promise error", err);
        }
      };
      getUser();
    }
  }, [currentUser, dispatch, logoutFailedLogin]);

  useEffect(() => {
    if (authRedux.user.id !== "") {
      try {
        const loadData = async () => {
          const [acc, usr, feed, campaign, yesterday, lastmonth, kpi] =
            await Promise.all([
              getData<dataState>("loadAccounts").then((data) => {
                console.log("loadAccounts done");
                return data?.data.accounts;
              }),
              getData<dataState>("loadUsers").then((data) => {
                console.log("loadUsers done");
                return data?.data!.users;
              }),
              getData<dataState>("loadFeeds").then((data) => {
                console.log("loadFeeds done");
                return data?.data.feeds;
              }),
              getCampaigns().then((data: ICampaigns[]) => {
                console.log("getCampaigns done");
                return data;
              }),
              getCount("countYesterdaysAssets").then((data) => {
                console.log("countYesterdaysAssets done");
                return data?.data!.noOfAssetsYesterday;
              }),
              getCount("countLastMonthsAssets").then((data) => {
                console.log("countLastMonthsAssets done");
                return data?.data!.noOfAssetsLastMonth;
              }),
              getKpis().then((data: IKpis[]) => {
                console.log("getKpis done");
                return data;
              }),
            ]);
          acc?.sort((a, b) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
            if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
            else return 0;
          });
          campaign?.sort((a, b) => {
            if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase())
              return -1;
            if (a.campaignName.toUpperCase() > b.campaignName.toUpperCase())
              return 1;
            else return 0;
          });
          feed?.sort((a, b) => {
            if (a.feedName.toUpperCase() < b.feedName.toUpperCase()) return -1;
            if (a.feedName.toUpperCase() > b.feedName.toUpperCase()) return 1;
            else return 0;
          });

          campaign.forEach((camp) => {
            if (!camp.assets) camp.assets = 0;
          });

          acc?.forEach((acc) => {
            const filteredCampaigns =
              campaign &&
              campaign.filter((campaign) => {
                if (acc.id === campaign.accountId) {
                  return campaign;
                } else return undefined;
              });

            let assetCount = 0;
            if (filteredCampaigns)
              for (const obj of filteredCampaigns) {
                if (obj.assets as number) {
                  assetCount += obj.assets;
                }
              }

            acc.totalAssets = assetCount;
          });

          dataObj.data = {
            accounts: acc!,
            users: usr!,
            feeds: feed!,
            campaigns: campaign!,
            noOfAssetsYesterday: yesterday!,
            noOfAssetsLastMonth: lastmonth!,
            kpis: kpi!,
          };
        };

        loadData().finally(() => {
          dispatch(updateData(dataObj));
        });
      } catch (err) {
        console.error(err);
      }
    }
    // eslint-disable-next-line
  }, [authRedux, dataObj]);

  return (
    <AuthContext.Provider value={currentUser}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
