import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks"
import { Button, Modal } from "react-bootstrap"
import {
  selectTopicToBeDeleted,
  setTopicToBeDeleted,
  deleteTopicById,
} from "../../../../redux/Guides"
import { deleteGuideTopic } from "../../../../services/GuideServices"

const DeleteGuideTopics: React.FC = () => {
  const dispatch = useAppDispatch()
  const topicToBeDeleted = useAppSelector(selectTopicToBeDeleted)
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    if (topicToBeDeleted) {
      setShow(true)
    }
  }, [topicToBeDeleted])

  const handleClose = () => {
    dispatch(setTopicToBeDeleted(null))
    setShow(false)
  }

  const handleDeleteClick = (topicId: string) => {
    if (!topicId) return
    deleteGuideTopic(topicId)
      .then(() => {
        dispatch(deleteTopicById(topicId))
        handleClose()
      })
      .catch(err => console.log("Err", err))
  }

  return (
    topicToBeDeleted && (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Whant to delete {topicToBeDeleted?.title}?</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <Button
            onClick={() => handleDeleteClick(topicToBeDeleted?.id)}
            variant="danger"
          >
            Delete
          </Button>
        </Modal.Body>
      </Modal>
    )
  )
}

export default DeleteGuideTopics
