export const ACCOUNT_PLANS = {
  disabled: "disabled",
  basic: "basic",
  pro: "pro",
  premium: "premium",
}
export const REELER_APP_HELP_URL = "https://app.reelertech.com/help/"
export const GUIDE_VIDEO_STORAGE_PATH = "reeler-guide-videos/"
export const GUIDE_VIDEO_THUMBNAIL_STORAGE_PATH =
  "reeler-guide-videos/thumbnails/"
export type TUserType = "disabled" | "disabled"

export const THUMBNAIL_SIZE_PREFIX = {
  h_200: "image@200_",
  h_500: "image@500_",
  base: "",
}

export const EMAIL_TEMPLATE_TYPES = ["Weekly Mon @ 10", "New Signup"]

export const EMAIL_TEMPLATE_CODES = {
  noOfWeeklyCollectedAssets: "{{no_of_weekly_collected_assets}}",
  recipientFirstName: "{{recipient_first_name}}",
  recipientLastName: "{{recipient_last_name}}",
  companyName: "{{company_name}}",
}
