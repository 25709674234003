import React, { useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { BiCopy } from "react-icons/bi"

const CopyIDIcon = () => {
  const [clicker, setClicker] = useState<boolean>(false)
  const renderToolTip = (props: any) =>
    !clicker ? (
      <Tooltip id="tooltip" {...props}>
        Copy ID
      </Tooltip>
    ) : (
      <Tooltip id="tooltip" {...props}>
        Copied
      </Tooltip>
    )

  return (
    <OverlayTrigger
      placement="top"
      // delay={{ show: 0, hide: 100 }}
      overlay={renderToolTip}
    >
      <span>
        <BiCopy
          style={{ cursor: "pointer" }}
          onClick={() => {
            setClicker(true)
            setTimeout(() => {
              setClicker(false)
            }, 1200)
          }}
        />
      </span>
    </OverlayTrigger>
  )
}

export default CopyIDIcon
