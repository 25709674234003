import React, { useEffect, useState } from "react";
import { InfoCard } from "../../../../components/commons/infocard/InfoCard";
import { selectData } from "../../../../redux/data";
import { useAppSelector } from "../../../../redux/hooks";

export const TotalAssetsYesterday = () => {
  const [assetsYesterday, setAssetsYesterday] = useState<number>(0);
  const { noOfAssetsYesterday } = useAppSelector(selectData);

  useEffect(() => {
    if (noOfAssetsYesterday) {
      setAssetsYesterday(noOfAssetsYesterday);
    }
  }, [noOfAssetsYesterday]);

  return <InfoCard label={"Assets yesterday"} kpi={assetsYesterday} />;
};

export default TotalAssetsYesterday;
