import { httpsCallable } from "firebase/functions"
import { functions } from "../firebase"
import { IUsers } from "../redux/data"

export const deleteUser = async (userId: string, accountId: string) => {
  const deleteUser = httpsCallable(functions, "deleteUser")

  try {
    const res = await deleteUser({
      userId: userId,
      accountId: accountId,
    })
    return res
  } catch (err) {
    console.error(err)
    return undefined
  }
}

export const updateUserNotification = async (userId: string, weeklyNotification: boolean) => {
  const updateUser = httpsCallable(functions, "updateUser")
  try {
    const res = await updateUser({
      userId: userId,
      payload:  {notifications: {weeklyNotification: weeklyNotification}}
    })
    return res
  } catch (err) {
    console.error(err)
    return undefined
  }
}
