import { Timestamp } from "firebase/firestore"
import moment from "moment"
/**
 * Calculate time difference between two dates
 *
 * @param date Historical date.
 
 * @return Formatted date string.
 */
export function renderDate(date: Timestamp, format = "YYYY-MM-DD hh:ss") {
  if (date?.nanoseconds) {
    moment.locale("sv")
    // add support for old formats (maybe with checks for nanoseconds etc)
    return moment(date.toDate()).format(format)
  } else {
    return moment(date).format(format)
  }
}
