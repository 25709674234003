import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaEdit, FaRegEye, FaEyeSlash, FaGripVertical, FaLink, FaTrash } from "react-icons/fa";
import * as S from "../../../../styles/styles";
import variables from "../../../../styles/variables";
import {
  clearTopicVideo,
  selectSelectedTopic,
  selectTopicVideoById,
  updateTopic,
  updateTopicsWithSelectedTopic,
  setVideoToBeDeleted,
} from "../../../../redux/Guides";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import { ROUTES } from "../../../../constants/routeConstants";
import ReelerButton from "../../../../components/commons/ReelerButton";
import {
  DragDropContext,
  Draggable,
  DropResult,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { GuideVideo } from "../../../../types/GuideVideo";
import { reorderArrayItem } from "../../../../utils/arrayUtils";
import { updateGuideVideoInDB } from "../../../../services/GuideServices";
import { StrictModeDroppable as Droppable } from "../../GuideTopicsPage/helpersDnD/strictModeDroppable";
import { GuideTopic } from "../../../../types/GuideTopic";
import { Container } from "react-bootstrap";
import DeleteGuideTopicVideo from "./DeleteGuideTopicVideo";
import copyToClipboard from "../../../../utils/CopyToClipbord";
import { REELER_APP_HELP_URL } from "../../../../constants/globalConstants";
import ReelerIconV2 from "../../../../components/commons/ReelerIconV2";
import { showMessage } from "../../../../redux/alertUserMessage";

const GuideTopicVideos: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedTopic = useAppSelector(selectSelectedTopic);
  const { topicId } = useParams();
  const navigate = useNavigate();

  const handleEditTopicVideo = (videoId: string) => {
    // Uppdatera redux state med rätt vald video
    dispatch(selectTopicVideoById(videoId));
    navigate(
      `${ROUTES.GuideTopicPage}/${topicId}${ROUTES.GuideVideoPage}/${videoId}`
    );
  };
  const handleNewTopicVideo = () => {
    // Uppdatera redux state med rätt vald video
    dispatch(clearTopicVideo());
    navigate(`${ROUTES.GuideTopicPage}/${topicId}${ROUTES.GuideVideoPage}`);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (!selectedTopic) {
      return;
    }

    const reorderedVideos: GuideVideo[] = reorderArrayItem(
      selectedTopic?.videos || [],
      result.source.index,
      result.destination.index
    );

    const updatedVideos = reorderedVideos.map((video, index) => {
      return { ...video, order: index };
    });

    //
    dispatch(
      updateTopic({ ...selectedTopic, videos: updatedVideos } as GuideTopic)
    );
    dispatch(updateTopicsWithSelectedTopic());
    const promises = updatedVideos.map(async (video) => {
      return await updateGuideVideoInDB(selectedTopic?.id, video);
    });

    Promise.all(promises)
      .then(() => {
        console.log("Saved videos to DB:", updatedVideos);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);

  const handleMouseEnter = (itemId: string) => {
    setHoveredItemId(itemId);
  };

  const handleMouseLeave = () => {
    setHoveredItemId(null);
  };

  const handleDeleteVideos = (video: GuideVideo) => {
    dispatch(setVideoToBeDeleted(video));
  };

  const shareVideo = (videoId: string) => {
    copyToClipboard(REELER_APP_HELP_URL + `${topicId}/${videoId}`);
    dispatch(showMessage({
      message:"Link copied",
      variant: "success",
    }))
  };

  return (
    <>
      <S.BigTableContainer>
        <S.BigTableTitle></S.BigTableTitle>
        <div className="d-flex  justify-content-end mb-3">
          <ReelerButton
            text="Upload Video"
            dispatch={() => handleNewTopicVideo()}
          />
        </div>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="videos">
            {(provided: DroppableProvided) => (
              <S.TableContainer maxHeight="100vh" minHeight="82px">
                <S.StyledTable>
                  <S.TableHead>
                    <tr className="w-auto">
                      <th scope="col" className="w-auto"></th>
                      <th scope="col" className="col-1"></th>
                      <th scope="col">Title</th>
                      <th scope="col">Description</th>
                      <th scope="col">
                        <div className="d-flex justify-content-center">
                          Visibility
                        </div>
                      </th>
                      <th scope="col">Created At</th>
                      <th scope="col"></th>
                    </tr>
                  </S.TableHead>

                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {selectedTopic?.videos?.map((video, index) => (
                      <Draggable
                        key={video.id}
                        draggableId={video.id}
                        index={index}
                      >
                        {(
                          provided: DraggableProvided,
                          snapshot: DraggableStateSnapshot
                        ) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              backgroundColor: snapshot.isDragging
                                ? "lightblue"
                                : "white",
                            }}
                            onMouseEnter={() => handleMouseEnter(video.id)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <td {...provided.dragHandleProps}>
                              <S.GrabIcon
                                isDragging={snapshot.isDragging}
                                hovered={hoveredItemId === video.id}
                              >
                                <FaGripVertical />
                              </S.GrabIcon>
                            </td>
                            <td>
                              {video.thumbnail?.url ? (
                                <div style={{ width: 100 }}>
                                  <img
                                    style={{
                                      backgroundImage: `url(${video.thumbnail?.url})`,
                                    }}
                                    className="thumbnail"
                                    alt=""
                                  />
                                </div>
                              ) : (
                                <video style={{ width: 100 }}>
                                  <source
                                    className="thumbnail"
                                    src={video.video?.url}
                                  />
                                </video>
                              )}
                            </td>
                            <td>{video.title}</td>
                            <td>{video.description}</td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <div className="align-itmes-center">
                                  {video.published ? (
                                    <FaRegEye color={`${variables.reeler}`} />
                                  ) : (
                                    <FaEyeSlash
                                      color={`${variables.reelerDanger}`}
                                    />
                                  )}
                                </div>
                              </div>
                            </td>
                            <td>
                              {selectedTopic?.createdAt
                                ?.toDate()
                                .toDateString()}
                            </td>

                            <td>
                              <Container className="d-flex justify-content-between align-items-center">
                                <ReelerIconV2
                                  onClick={() => handleEditTopicVideo(video.id)}
                                  tooltip="Edit"
                                >
                                  <FaEdit
                                    size={17}
                                    color={`${variables.reeler}`}
                                  />
                                </ReelerIconV2>
                                <ReelerIconV2
                                  onClick={() => shareVideo(video.id)}
                                  tooltip="Copy link"
                                >
                                  <FaLink size={14}/>
                                </ReelerIconV2>
                                <ReelerIconV2
                                  onClick={() => handleDeleteVideos(video)}
                                  disabled={false}
                                  tooltip="Delete?"
                                >
                                  <FaTrash />
                                </ReelerIconV2>
                              </Container>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                </S.StyledTable>
              </S.TableContainer>
            )}
          </Droppable>
        </DragDropContext>
      </S.BigTableContainer>
      <DeleteGuideTopicVideo />
    </>
  );
};

export default GuideTopicVideos;
