import React, { useEffect } from "react"

import * as S from "../../../styles/styles"

import ReelerButton from "../../../components/commons/ReelerButton"
import CreateNewNotificationModal from "./CreateNewNotificationModal"

import {
  initializeNotifications,
  selectNotifications,
  setNotification,
} from "../../../redux/Notifications"

import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { Notification } from "../../../types/Notification"
import { fetchNotifications } from "../../../services/NotificationService"
import NotificationRow from "./Notification"

const Notifications: React.FC = () => {
  const notifications = useAppSelector(selectNotifications)
  const dispatch = useAppDispatch()
  useEffect(() => {
    const loadNotifications = async () => {
      const notification = await fetchNotifications()
      dispatch(initializeNotifications(notification))
    }
    loadNotifications()
  }, [])

  const handleCreateNewNotification = () => {
    const n = {
      notificationName: "",
      title: "",
      message: "",
      variant: "info",
      type: "",
      published: false,
      customers: [],
    } as Notification

    dispatch(setNotification(n))
  }

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <CreateNewNotificationModal />
      </div>
      <S.BigTableContainer>
        <S.BigTableTitle></S.BigTableTitle>
        <div className="d-flex justify-content-end mb-3">
          <ReelerButton
            dispatch={handleCreateNewNotification}
            text="Create new notification"
          />
        </div>

        <S.TableContainer>
          <S.StyledTable>
            <S.TableHead className=" w-auto">
              <tr className=" w-auto">
                <th scope="col" className="col-1"></th>
                <th scope="col" className="col-2">
                  Title
                </th>
                <th scope="col" className="col-2">
                  Message
                </th>
                <th scope="col" className="col-2">
                  Variant
                </th>
                <th scope="col" className="col-3">
                  Customers
                </th>
                <th></th>
              </tr>
            </S.TableHead>
            <tbody>
              {notifications.map(notification => (
                <NotificationRow
                  key={notification.id}
                  notification={notification}
                />
              ))}
            </tbody>
          </S.StyledTable>
        </S.TableContainer>
      </S.BigTableContainer>
    </>
  )
}

export default Notifications
