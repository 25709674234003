import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { InfoCard } from "../../../../components/commons/infocard/InfoCard";
import { selectActiveAccount } from "../../../../redux/activeAccount";
import { useAppSelector } from "../../../../redux/hooks";
import { getCount } from "../../../../services/getCount";

export const TotalAssetsYesterday = () => {
  const activeAccount = useAppSelector(selectActiveAccount);
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const totalString = "Assets yesterday";

  useEffect(() => {
    setIsLoading(true);
    if (activeAccount.id) {
      getCount("countYesterdaysAssets", activeAccount.id).then((res) => {
        if (res?.data?.noOfAssetsYesterday) {
          setCount(res!.data!.noOfAssetsYesterday);
          setIsLoading(false);
        }
      });
    }
  }, [activeAccount]);

  return isLoading ? (
    <InfoCard
      label={totalString}
      children={<Spinner size="sm" animation="border" />}
    />
  ) : (
    <InfoCard label={totalString} kpi={count} />
  );
};

export default TotalAssetsYesterday;
