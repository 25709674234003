import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks"
import { selectFilter } from "../../../../redux/filter"
import SpinnerComponent from "../../../../components/commons/SpinnerComponent"
import * as S from "../../../../styles/styles"
import { IAccounts, selectData } from "../../../../redux/data"
import { updateActiveAccount } from "../../../../redux/activeAccount"
import { useNavigate } from "react-router-dom"
import { searchbarDashboard } from "../../../../utils/searchbarDashboard"
import {
  sortAccountAssets,
  sortAccountByDate,
  sortAccountByName,
} from "../../../../utils/sort"
import { AiOutlineSortAscending } from "react-icons/ai"
import { BsSortDown } from "react-icons/bs"
import InstagramConnection from "./InstagramConnection"
import HashtagContent from "./HashtagContent"
import LatestActivityDashBoard from "./LatestActivityDashBoard"
import UploadContent from "./UploadContent"
import NumberOfAssetsForAccount from "./NumberOfAssetsForAccount"
import { ROUTES } from "../../../../constants/routeConstants"

export const DashBoardTable = () => {
  const { accounts } = useAppSelector(selectData)
  const { filter } = useAppSelector(selectFilter)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [filtAcc, setFiltAcc] = useState<IAccounts[]>([...accounts])
  const [searchText, setSearchText] = useState<string>("Search...")

  useEffect(() => {
    if (accounts.length === 0) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [accounts])

  useEffect(() => {
    if (filter.plans.length > 0) {
      let filtredAccounts = accounts.filter(acc =>
        filter.plans.includes(acc.plan)
      )
      let searchResults = searchbarDashboard(filtredAccounts, searchText)
      setFiltAcc(searchResults)
    } else {
      setFiltAcc(searchbarDashboard(accounts, searchText))
    }
  }, [searchText, accounts, filter])

  const capitalizedWord = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  return isLoading ? (
    <S.TableContainer className="align-top mt-5 pt-5" height="500px">
      <SpinnerComponent />
    </S.TableContainer>
  ) : (
    <S.TableContainer maxHeight="600px">
      <S.StyledTable>
        <S.TableHead>
          <tr>
            <th
              scope="col"
              className="col-2 pointLink"
              onClick={() => {
                const tempAcc = sortAccountByName(filtAcc)
                setFiltAcc(tempAcc)
              }}
            >
              Account
              <AiOutlineSortAscending
                className="ms-1"
                style={{ fontSize: "22px" }}
              />
              <input
                className="ms-3"
                type="text"
                maxLength={48}
                value={searchText}
                onChange={e => {
                  setSearchText(e.target.value)
                }}
                onClick={() => {
                  setSearchText("")
                }}
                onBlur={() => {
                  if (searchText === "") setSearchText("Search...")
                }}
              />
            </th>
            <th scope="col" className="col-1">
              Plan
            </th>
            <th scope="col" className="col-1">
              Assets
            </th>
            <th scope="col" className="col-1">
              User Activity
            </th>
            <th scope="col" className="col-1">
              Hashtag Content
            </th>
            <th scope="col" className="col-1">
              Upload Content
            </th>
            <th scope="col" className="col-1">
              Instagram
            </th>
            <th
              scope="col"
              className="col-1 pointLink"
              onClick={() => {
                const tempAcc = sortAccountByDate(filtAcc)
                setFiltAcc(tempAcc)
              }}
            >
              Created
              <BsSortDown className="ms-1" style={{ fontSize: "20px" }} />
            </th>
          </tr>
        </S.TableHead>
        {filtAcc.length !== 0 ? (
          <tbody>
            {filtAcc.map(account => {
              return (
                <tr className="fw-normal" key={account.id}>
                  <th>
                    <span
                      className="ms-2 table-link"
                      onClick={() => {
                        dispatch(
                          updateActiveAccount({
                            account: {
                              id: account.id,
                              userId: account.userId,
                              name: account.name,
                              plan: account.plan,
                              createdAt: {
                                _seconds: account.createdAt._seconds,
                                _nanoseconds: account.createdAt._nanoseconds,
                              },
                            },
                          })
                        )
                        navigate(ROUTES.customer)
                      }}
                    >
                      {!account.name ? "(No name exist)" : account.name}
                    </span>
                  </th>
                  <td className="align-middle">
                    {account.plan ? capitalizedWord(account.plan) : "n/a"}
                  </td>
                  <td className="align-middle">
                    <NumberOfAssetsForAccount accountId={account.id} />
                  </td>

                  <td className="align-middle">
                    <LatestActivityDashBoard accountId={account.id} />
                  </td>
                  <td className="align-middle">
                    <HashtagContent accountId={account.id} />
                  </td>
                  <td className="align-middle">
                    <UploadContent accountId={account.id} />
                  </td>
                  <td className="align-middle">
                    <InstagramConnection integrations={account.integrations} />
                  </td>
                  <td className="align-middle">
                    <span>
                      {new Date(account.createdAt._seconds * 1000)
                        .toLocaleString("sv-SE")
                        .substring(0, 10)}
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        ) : (
          <tbody>
            <tr className="fw-normal">
              <th>
                <span className="ms-2 col-12">(No Accounts)</span>
              </th>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        )}
      </S.StyledTable>
    </S.TableContainer>
  )
}
