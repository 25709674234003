import React, { useState, useEffect } from "react"
import { Spinner } from "react-bootstrap"
import { getNumberOfAssetsInCampaign } from "../../../services/getNumberOfAssetsInCampaign"

interface Props {
  campaignId: string
}

const CampaignNumberOfAssets = ({ campaignId }: Props) => {
  const [numberOfAssets, setNumberOfAssets] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (campaignId) {
      getNumberOfAssetsInCampaign(campaignId).then(res => {
        if (res?.data?.noOfAssets) {
          setNumberOfAssets(res?.data?.noOfAssets)
          setIsLoading(false)
        } else {
          setNumberOfAssets(0)
          setIsLoading(false)
        }
      })
    }
  }, [campaignId])

  return isLoading ? (
    <Spinner animation="border" size="sm" />
  ) : (
    <span>{numberOfAssets}</span>
  )
}

export default CampaignNumberOfAssets
