import React from "react"

// Redux
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks"
import { updateAccountInData } from "../../../../redux/data"
import { changeAccountPlan } from "../../../../services/changeAccountPlan"
import {
  selectActiveAccount,
  updateActiveAccount,
} from "../../../../redux/activeAccount"
// Third party
import { Dropdown } from "react-bootstrap"

// Reeler
import { ACCOUNT_PLANS } from "../../../../constants/globalConstants"
import variables from "../../../../styles/variables"

const TogglePlan = () => {
  const dispatch = useAppDispatch()
  const activeAccount = useAppSelector(selectActiveAccount)

  const handleChange = (plan: string) => {
    changeAccountPlan(activeAccount.id, plan)
    dispatch(
      updateAccountInData({
        id: activeAccount.id,
        account: {
          ...activeAccount,
          plan,
        },
      })
    )
    dispatch(
      updateActiveAccount({
        account: {
          ...activeAccount,
          plan,
        },
      })
    )
  }

  return (
    <Dropdown drop="down">
      <Dropdown.Toggle
        style={{ color: "black", border: "none" }}
        variant="whitegray"
        id="dropdown-basic"
        className="ms-3"
      >
        Account plan
      </Dropdown.Toggle>
      <span>{`active: ${
        activeAccount.plan ? activeAccount.plan : "no plan active"
      }`}</span>
      <Dropdown.Menu
        variant="dark"
        style={{ backgroundColor: variables.reelerBlack }}
        align="end"
      >
        <Dropdown.Item onClick={() => handleChange(ACCOUNT_PLANS.disabled)}>
          {"Disabled"}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleChange(ACCOUNT_PLANS.basic)}>
          {"Basic"}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleChange(ACCOUNT_PLANS.pro)}>
          {"Pro"}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleChange(ACCOUNT_PLANS.premium)}>
          {"Premium"}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default TogglePlan
