import React, { useState } from "react"
import * as S from "../../../styles/styles"
import { FaEdit, FaGripVertical, FaTrash } from "react-icons/fa"
import variables from "../../../styles/variables"
import ReelerIconV2 from "../../../components/commons/ReelerIconV2"
import { Container } from "react-bootstrap"
import {
  deletEmailTemplate,
  selectEmailTemplates,
  setEmailTemplate,
  setEmailToBeDeleted,
} from "../../../redux/Communication"
import EditEmailTemplateModal from "./EditEmailTemplateModal"
import { EmailTemplate } from "../../../types/EmailTemplate"
import ReelerButton from "../../../components/commons/ReelerButton"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { renderDate } from "../../../utils/RenderDate"
import DeleteModal from "../../../components/commons/DeleteModal"
import { deleteEmailTemplate } from "../../../services/EmailTemplateService"

const Templates: React.FC = () => {
  const dispatch = useAppDispatch()
  const emailTemplates = useAppSelector(selectEmailTemplates)
  

  const handleEditTemplate = (emailTemplate: EmailTemplate) => {
    dispatch(setEmailTemplate(emailTemplate))
  }

  const handleDeleteTemplate = async (template: EmailTemplate) => {
    if (!template.id) return;

    try {
      await deleteEmailTemplate(template.id);
      dispatch(deletEmailTemplate(template));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <EditEmailTemplateModal />
      <S.BigTableContainer>
        <S.BigTableTitle></S.BigTableTitle>

        <div className="d-flex  justify-content-end mb-3">
          <ReelerButton
            dispatch={() =>
              handleEditTemplate({
                templateName: "",
                subject: "",
                from: {
                  name: "",
                },
                body: {
                  text: "",
                  html: "",
                },
              } as EmailTemplate)
            }
            text="Create new template"
          />
        </div>
        <S.TableContainer>
          <S.StyledTable>
            <S.TableHead>
              <tr className="w-auto">
                <th scope="col" className="col-3">
                  Template name
                </th>
                <th scope="col" className="col-3">
                  Subject
                </th>
                <th scope="col" className="col">
                  Type
                </th>
                <th scope="col">Created At</th>
                <th></th>
              </tr>
            </S.TableHead>
            <tbody>
              {emailTemplates.map((template, index) => (
                <tr key={index}>
                  <td>{template.templateName}</td>
                  <td>{template.subject}</td>
                  <td>{template.type}</td>
                  <td>
                    {template.createdAt &&
                      renderDate(template.createdAt, "YYYY-MM-DD")}
                  </td>
                  <td>
                    <Container className="d-flex justify-content-end align-items-center">
                      <ReelerIconV2
                        tooltip="Edit"
                        onClick={() => handleEditTemplate(template)}
                      >
                        <FaEdit size={17} color={`${variables.reeler}`} />
                      </ReelerIconV2>
                      <DeleteModal 
                      title={`Delete ${template.templateName}?`}
                      body={`Do you want to delete ${template.templateName}`}
                      onDelete={() => handleDeleteTemplate(template)}/>
                    </Container>
                  </td>
                </tr>
              ))}
            </tbody>
          </S.StyledTable>
        </S.TableContainer>
      </S.BigTableContainer>
    </>
  )
}

export default Templates
