import React, { useState } from "react"
import ReelerButton from "../../../components/commons/ReelerButton"
import { Button, Dropdown, Form, Modal } from "react-bootstrap"
import {
  EMAIL_TEMPLATE_CODES,
  EMAIL_TEMPLATE_TYPES,
} from "../../../constants/globalConstants"
import ReelerIconV2 from "../../../components/commons/ReelerIconV2"
import copyToClipboard from "../../../utils/CopyToClipbord"
import { BiCopy } from "react-icons/bi"
import { useAppSelector, useAppDispatch } from "../../../redux/hooks"
import {
  addNewEmailTemplate,
  closeEmailTemplate,
  selectedEmailTemplate,
  setEmailTemplate,
  updateEmailTemplates,
} from "../../../redux/Communication"
import { EmailTemplate } from "../../../types/EmailTemplate"
import {
  createNewEmailTemplateDB,
  updateEmailTemplateDB,
} from "../../../services/EmailTemplateService"
import { useAlertUserMessage } from "../../../hooks/useAlertUserMessage"

const EditEmailTemplateModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const [saving, setSaving] = useState<boolean>(false)
  const emailTemplate = useAppSelector(selectedEmailTemplate)
  const { setAlertMessage } = useAlertUserMessage()

  const handleClose = () => {
    dispatch(closeEmailTemplate())
  }

  const handleTypes = (type: string) => {
    dispatch(
      setEmailTemplate({
        ...emailTemplate,
        type,
      } as EmailTemplate)
    )
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    dispatch(
      setEmailTemplate({
        ...emailTemplate,
        [name]: value,
      } as EmailTemplate)
    )
  }

  const handleSave = async () => {
    if (!emailTemplate || Object.keys(emailTemplate).length === 0) return
    setSaving(true)

    // Kolla om vi skall skapa ett nytt template dokument i Firebase eller uppdatera ett existerande
    if (emailTemplate?.id) {
      try {
        await updateEmailTemplateDB(emailTemplate)
        dispatch(updateEmailTemplates(emailTemplate))
        setAlertMessage({
          message: "Successfully updated template",
          variant: "success",
        })
        setSaving(false)
      } catch (err) {
        setAlertMessage({
          message: "Could not update template",
          variant: "danger",
        })
        setSaving(false)

        console.log(err)
      }
    } else {
      try {
        const newEmailTemplate = await createNewEmailTemplateDB(emailTemplate)
        dispatch(addNewEmailTemplate(newEmailTemplate))
        setAlertMessage({
          message: "Successfully created a new email template",
          variant: "success",
        })
        setSaving(false)
      } catch (err) {
        console.log(err)
        setAlertMessage({
          message: "Could not create a new email template",
          variant: "danger",
        })
        setSaving(false)
      }
    }
  }

  return (
    <>
      <Modal show={emailTemplate ? true : false} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {emailTemplate?.id ? "Edit template" : "Create new email template"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="emailTemplateName">
              <Form.Label>Internal email template name</Form.Label>

              <Form.Control
                value={emailTemplate?.templateName}
                type="text"
                name="templateName"
                onChange={handleChange}
                placeholder="Add an internal template name"
              />
              <Form.Text muted>Set an internal email template name.</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="emailTemplateType">
              <Dropdown className="rounded">
                <Dropdown.Toggle
                  variant="secondary"
                  id="dropdown-basic"
                  size="sm"
                  style={{ padding: "7px 12px" }}
                >
                  {emailTemplate?.type || "select a type"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {EMAIL_TEMPLATE_TYPES.map((type, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => handleTypes(type)}
                    >
                      {type}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Form.Text muted>Choose email template type</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="senderAlias">
              <Form.Label>Sender alias</Form.Label>
              <Form.Control
                value={emailTemplate?.from?.name}
                type="text"
                placeholder="Sender alias"
                name="senderAlias"
                onChange={e =>
                  dispatch(
                    setEmailTemplate({
                      ...emailTemplate,
                      from: {
                        ...emailTemplate?.from,
                        name: e.target.value,
                      },
                    } as EmailTemplate)
                  )
                }
              />
              <Form.Text muted>Add a sender alias</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>Subject</Form.Label>

              <Form.Control
                onChange={handleChange}
                name="subject"
                type="text"
                placeholder="Add a subject"
                value={emailTemplate?.subject}
              />
              <Form.Text muted>
                Write an email subject for this email template. Include
                available email codes.
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="emailBody">
              <Form.Label>Email template body (text)</Form.Label>
              <Form.Control
                name="body"
                value={emailTemplate?.body?.text}
                onChange={e =>
                  dispatch(
                    setEmailTemplate({
                      ...emailTemplate,
                      body: {
                        ...emailTemplate?.body,
                        text: e.target.value,
                        html: e.target.value,
                      },
                    } as EmailTemplate)
                  )
                }
                as="textarea"
                rows={10}
              />
              <Form.Text muted>
                Write the email body text for this template. Available email
                codes:
                <ul>
                  <li className="d-flex flex-row align-items-center">
                    <span style={{ fontWeight: "bold" }}>
                      {EMAIL_TEMPLATE_CODES.recipientFirstName}
                    </span>
                    <ReelerIconV2
                      onClick={() =>
                        copyToClipboard(EMAIL_TEMPLATE_CODES.recipientFirstName)
                      }
                      tooltip="Recipients first name"
                    >
                      <BiCopy />
                    </ReelerIconV2>
                  </li>
                  <li className="d-flex flex-row align-items-center">
                    <span style={{ fontWeight: "bold" }}>
                      {EMAIL_TEMPLATE_CODES.recipientLastName}
                    </span>
                    <ReelerIconV2
                      onClick={() =>
                        copyToClipboard(EMAIL_TEMPLATE_CODES.recipientLastName)
                      }
                      tooltip="Recipients last name"
                    >
                      <BiCopy />
                    </ReelerIconV2>
                  </li>
                  <li className="d-flex flex-row align-items-center">
                    <span style={{ fontWeight: "bold" }}>
                      {EMAIL_TEMPLATE_CODES.noOfWeeklyCollectedAssets}
                    </span>
                    <ReelerIconV2
                      onClick={() =>
                        copyToClipboard(
                          EMAIL_TEMPLATE_CODES.noOfWeeklyCollectedAssets
                        )
                      }
                      tooltip="No of weekly collected assets"
                    >
                      <BiCopy />
                    </ReelerIconV2>
                  </li>
                  <li className="d-flex flex-row align-items-center">
                    <span style={{ fontWeight: "bold" }}>
                      {EMAIL_TEMPLATE_CODES.companyName}
                    </span>
                    <ReelerIconV2
                      onClick={() =>
                        copyToClipboard(EMAIL_TEMPLATE_CODES.companyName)
                      }
                      tooltip="Company name"
                    >
                      <BiCopy />
                    </ReelerIconV2>
                  </li>
                </ul>
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <ReelerButton
            disabled={!emailTemplate || Object.keys(emailTemplate).length === 0}
            loading={saving}
            text={emailTemplate?.id ? "Save" : "Create new template"}
            dispatch={handleSave}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditEmailTemplateModal
