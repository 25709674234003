import styled from "styled-components"

export const FilterContainer = styled.div`
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const FilterOptions = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const FilterHeading = styled.h6`
  display: flex;
  font-weight: bold;
  font-size: 0.9rem;
  margin: 0;
`
