import {
  addDoc,
  getDocs,
  query,
  doc,
  updateDoc,
  deleteDoc,
  orderBy,
} from "firebase/firestore"
import { httpsCallable } from "firebase/functions"

import { database, functions } from "../firebase"

import { Notification } from "../types/Notification"
import withoutProperty from "../utils/withoutProperty"

export const fetchNotifications = async (): Promise<Notification[]> => {
  let notifications: Notification[] = []
  // Get all guides from firestore

  const q = query(database.notificationsTemplates, orderBy("createdAt"))
  const querySnapshot = await getDocs(q)

  querySnapshot.forEach(notificationDoc => {
    // Format
    const notification = database.formatDoc(notificationDoc) as Notification
    notifications.push(notification)
  })

  return notifications
}

export const createNewNotificationDB = async (
  notification: Notification
): Promise<Notification> => {
  const docRef = await addDoc(database.notificationsTemplates, {
    ...notification,
    createdAt: database.getCurrentTimestamp(),
  })

  return { ...notification, id: docRef.id } as Notification
}

export const updateNotificationDB = async (
  notification: Notification
): Promise<void> => {
  const notificationRef = doc(database.notificationsTemplates, notification.id)

  const notificationWithoutID = withoutProperty(notification, "id")

  return await updateDoc(notificationRef, notificationWithoutID).catch(error =>
    console.log("Nothing went wrong", error)
  )
}

export const deleteNotificatinDB = async (
  notificationId: string
): Promise<void> => {
  if (!notificationId) return

  const deleteNotificationRef = doc(
    database.notificationsTemplates,
    notificationId
  )

  return await deleteDoc(deleteNotificationRef).catch(error =>
    console.log("Something went wrong", error)
  )
}

export const publishNotification = async (
  notificationId: string
): Promise<void> => {
  if (!notificationId) return

  const publishNotificationMessages = httpsCallable(
    functions,
    "publishNotificationMessages"
  )

  try {
    await publishNotificationMessages({
      notificationTemplateId: notificationId,
    })
  } catch (err) {
    console.error(err)
    return undefined
  }
}
