import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

interface Props {
  text?: string
  children: React.ReactNode
}

const ReelerTooltip = (props: Props) => {
  const renderToolTip = () => {
    return <Tooltip id="tooltip">{props.text}</Tooltip>
  }

  return (
    <OverlayTrigger
      placement="bottom"
      // delay={{ show: 0, hide: 100 }}
      overlay={renderToolTip}
    >
      <span>{props.children}</span>
    </OverlayTrigger>
  )
}

export default ReelerTooltip
