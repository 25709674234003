import React, { useEffect, useState } from "react";
import { InfoCard } from "../../../../components/commons/infocard/InfoCard";
import { selectData } from "../../../../redux/data";
import { useAppSelector } from "../../../../redux/hooks";

export const TotalCampaigns = () => {
  const [campaignsLength, setCampaignsLength] = useState<number>(0);
  const { campaigns } = useAppSelector(selectData);

  useEffect(() => {
    if (campaigns) {
      setCampaignsLength(campaigns.length);
    }
  }, [campaigns]);

  return <InfoCard label={"Campaigns"} kpi={campaignsLength} />;
};

export default TotalCampaigns;
