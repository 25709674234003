import React, { useEffect, useState } from "react";
import { InfoCard } from "../../../../components/commons/infocard/InfoCard";
import { selectData } from "../../../../redux/data";
import { useAppSelector } from "../../../../redux/hooks";

export const TotalFeeds = () => {
  const [feedsLength, setFeedsLength] = useState<number>(0);
  const { feeds } = useAppSelector(selectData);

  useEffect(() => {
    if (feeds) {
      setFeedsLength(feeds.length);
    }
  }, [feeds]);

  return <InfoCard label={"Feeds"} kpi={feedsLength} />;
};

export default TotalFeeds;
