import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import GlobalStyles from "./styles/globalStyles"
import { AuthProvider } from "./contexts/AuthContext"
import { Provider } from "react-redux"
import { store } from "./redux/store"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <GlobalStyles />
        <App />
      </AuthProvider>
    </Provider>
  </React.StrictMode>
)
