import React from "react";
import { InfoCard } from "../../../../components/commons/infocard/InfoCard";
import { selectActiveAccount } from "../../../../redux/activeAccount";
import { selectData } from "../../../../redux/data";
import { useAppSelector } from "../../../../redux/hooks";

export const TotalFeeds = () => {
  const { feeds } = useAppSelector(selectData);
  const activeAccount = useAppSelector(selectActiveAccount);

  const filteredFeeds =
    feeds &&
    feeds.filter((feed) => {
      if (activeAccount.id === feed.accountId) {
        return feed;
      } else return undefined;
    });

  return <InfoCard label={"Feeds"} kpi={filteredFeeds?.length} />;
};

export default TotalFeeds;
