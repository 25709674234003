import styled from "styled-components"
import variables from "../../../styles/variables"

export const Icon = styled.span`
  width: 30px;
  height: 30px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 18px;
  background: none;

  &:hover {
    background: ${variables.reelerGreyDark};
    transition: all 0.3s;
  }
`
