import React from "react";
import { Container } from "react-bootstrap";

export default function Footer() {
  return (
    <div className="d-flex align-items-end mt-3" style={{ height: "10vh" }}>
      <Container
        fluid
        className="d-flex justify-content-center align-items-center footer p-3 flex-grow-1"
      >
        <small className="text-muted">&copy; {new Date().getFullYear()}</small>

        <span className="text-muted logo ms-2" style={{ fontSize: "18px" }}>
          {" "}
          Reeler{" "}
        </span>
      </Container>
    </div>
  );
}
