import React from "react";
import * as S from "./infoCard.styles";

interface Props {
  label: string;
  kpi?: number;
  children?: JSX.Element;
}

export const InfoCard = (info: Props) => {
  return (
    <S.InfoCardContainer>
      <S.InfoCardLabel>{info.label}</S.InfoCardLabel>
      <S.InfoKPIContainer>
        {info.kpi}
        {info.children}
      </S.InfoKPIContainer>
    </S.InfoCardContainer>
  );
};
