import styled from "styled-components"

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  display: inline-block;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
`

export const DeleteButton = styled.span`
  color: white;
  background: none;
  border: none;
  font-size: 1.5rem;
  display: flex;
  justify-content: flex-end;
  top: 0;
  right: 0;
  cursor: pointer;
  position: absolute;
  padding: 2px;
`
