import React, { useEffect, useState, useMemo } from "react"
import CopyIDIcon from "../../../../components/commons/CopyIDIcon"
import HashtagConnection from "./HashtagConnection"
import { searchbarCampaign } from "../../../../utils/searchbarCampaign"
import { sortCampaignByDate, sortCampaignByName } from "../../../../utils/sort"
import { selectActiveAccount } from "../../../../redux/activeAccount"
import {
  ICampaigns,
  selectData,
  deleteCampaignInData,
} from "../../../../redux/data"
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks"
import * as S from "../../../../styles/styles"
import LastAssetCampaign from "./LastAssetCampaign"
import CampaignNumberOfAssets from "../CampaignNumberOfAssets"
import { AiOutlineSortAscending } from "react-icons/ai"
import { BsSortDown } from "react-icons/bs"
import { FaTrash } from "react-icons/fa"
import { handleDeleteCampaign } from "../../../../services/services"
import { Modal } from "react-bootstrap"
import ReelerButton from "../../../../components/commons/ReelerButton"
import ExtractCratorHashtags from "../ExtractCratorHashtags"

export const CustomerCampaignTable = () => {
  const dispatch = useAppDispatch()
  const { campaigns } = useAppSelector(selectData)
  const activeAccount = useAppSelector(selectActiveAccount)
  const [searchText, setSearchText] = useState<string>("Search...")

  const filteredCampaigns = useMemo(() => {
    const tempCamp =
      campaigns &&
      campaigns.filter(campaign => {
        if (activeAccount.id === campaign.accountId) {
          return campaign
        } else return undefined
      })
    return tempCamp
  }, [activeAccount.id, campaigns])

  const [filtCamp, setFiltCamp] = useState<ICampaigns[]>([...filteredCampaigns])

  useEffect(() => {
    const result = searchbarCampaign(filteredCampaigns, searchText)
    setFiltCamp([...result])
  }, [searchText, filteredCampaigns])

  interface Props {
    campaignId: string
  }
  const DeleteCampaignModal = ({ campaignId }: Props) => {
    const [open, setOpen] = useState<boolean>(false)
    const [deleting, setDeleting] = useState<boolean>(false)
    const deleteCampaign = async (campaignId: string) => {
      setDeleting(true)
      // Delete campaign from firestore
      await handleDeleteCampaign(campaignId)
      // Delete campaign from Redux
      dispatch(deleteCampaignInData(campaignId))
      setDeleting(false)
      setOpen(false)
    }

    return (
      <>
        <S.IconContainer onClick={() => setOpen(true)}>
          <FaTrash />
        </S.IconContainer>
        <Modal centered show={open} onHide={() => setOpen(false)}>
          <Modal.Body>
            <Modal.Title>
              Are you sure you want to delete this campaign?
            </Modal.Title>
            <Modal.Body>
              If you delete this campaign you cannot recreate it.
            </Modal.Body>
            <Modal.Footer>
              <span className="link mr-2" onClick={() => setOpen(false)}>
                Close
              </span>
              <ReelerButton
                loading={deleting}
                dispatch={() => deleteCampaign(campaignId)}
                text="Delete campaign"
                styleClass="btn-delete"
              />
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  return (
    <S.TableContainer maxHeight="492px" minHeight="82px">
      <S.StyledTable>
        <S.TableHead>
          <tr>
            <th
              scope="col"
              className="col-3 pointLink"
              onClick={() => {
                const tempCamp = sortCampaignByName(filteredCampaigns)
                setFiltCamp(tempCamp)
              }}
            >
              Title
              <AiOutlineSortAscending
                className="ms-1"
                style={{ fontSize: "20px" }}
              />
              <input
                className="ms-3"
                type="text"
                maxLength={48}
                value={searchText}
                onChange={e => {
                  setSearchText(e.target.value)
                }}
                onClick={() => {
                  setSearchText("")
                }}
                onBlur={() => {
                  if (searchText === "") setSearchText("Search...")
                }}
              />
            </th>
            <th scope="col" className="col-1">
              ID
            </th>
            <th scope="col" className="col-1 pointLink">
              Assets
            </th>
            <th scope="col" className="col-1">
              Last asset
            </th>
            <th scope="col" className="col-1">
              Hashtag
            </th>
            <th scope="col" className="col-1">
              Status
            </th>
            <th scope="col" className="col-1">
              Type
            </th>
            <th
              scope="col"
              className="col-2 pointLink"
              onClick={() => {
                const tempCamp = sortCampaignByDate(filteredCampaigns)
                setFiltCamp(tempCamp)
              }}
            >
              Created
              <BsSortDown className="ms-1" style={{ fontSize: "20px" }} />
            </th>
            <th scope="col" className="col-1"></th>
          </tr>
        </S.TableHead>

        {filtCamp.length !== 0 ? (
          <tbody>
            {filtCamp.map(campaign => {
              const createdAt = new Date(campaign.createdAt.seconds * 1000)

              return (
                <tr className="fw-normal" key={campaign.id}>
                  <th>
                    <span className="ms-2">{campaign.campaignName}</span>
                  </th>
                  <td className="align-middle">
                    <span
                      className="ms-1"
                      onClick={() => {
                        navigator.clipboard.writeText(campaign.id)
                      }}
                    >
                      <CopyIDIcon />
                    </span>
                  </td>
                  <td className="align-middle">
                    <CampaignNumberOfAssets campaignId={campaign.id} />
                  </td>
                  <td className="align-middle">
                    <LastAssetCampaign campaignId={campaign.id} />
                  </td>
                  <td className="align-middle">
                    <HashtagConnection hashtag={campaign.hashtag} />
                  </td>
                  <td className="align-middle">
                    <span>{campaign.status}</span>
                  </td>
                  <td className="align-middle">
                    <span>{campaign.campaignType}</span>
                  </td>
                  <td className="align-middle">
                    <span>
                      {createdAt.toLocaleString("sv-SE").substring(0, 10)}
                    </span>
                  </td>
                  <td className="align-middle">
                    <span>
                      <DeleteCampaignModal campaignId={campaign.id} />
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        ) : (
          <tbody>
            <tr className="fw-normal">
              <th>
                <span className="ms-2 col-12">(No Campaigns)</span>
              </th>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        )}
      </S.StyledTable>
    </S.TableContainer>
  )
}
