import styled from "styled-components";
import variables from "../../../styles/variables";

export const InfoCardContainer = styled.div`
  margin: 0.5rem !important;
`;
export const InfoCardLabel = styled.h6`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  margin: 0;
`;

export const InfoKPIContainer = styled.div`
  background-color: ${variables.reelerBlack};
  color: ${variables.reeler};
  width: 150px;
  height: 70px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
`;
