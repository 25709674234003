import React, { useEffect, useState } from "react"
import { selectData } from "../../../../redux/data"
import { useAppSelector } from "../../../../redux/hooks"
import renderColoredSpan from "../../../../utils/renderColoredSpan"

interface Props {
  accountId: string
}

const LatestActivityDashBoard = ({ accountId }: Props) => {
  const { users } = useAppSelector(selectData)
  const [date, setDate] = useState<Date | undefined>(undefined)

  useEffect(() => {
    const filteredUsers =
      users &&
      users.filter(user => {
        if (
          user.accounts &&
          Array.isArray(user.accounts) &&
          user.accounts.includes(accountId) &&
          !user?.email.includes("reelertech.com") &&
          user.latestActivity
        ) {
          return user
        } else return undefined
      })

    const latestActive = filteredUsers.sort((a, b) => {
      return b.latestActivity?._seconds - a.latestActivity?._seconds
    })

    if (latestActive[0]?.latestActivity?._seconds) {
      setDate(new Date(latestActive[0].latestActivity._seconds * 1000))
    } else {
      setDate(undefined)
    }
  }, [accountId, users])

  return date ? renderColoredSpan(date.toLocaleDateString("sv-SE")) : <></>
}

export default LatestActivityDashBoard
