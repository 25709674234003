import React from "react";

import { Login } from "../src/components/authentication/Login";
import { useAuth } from "./contexts/AuthContext";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { NotFound } from "./pages/NotFound";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { Customer } from "./pages/customer/Customer";
import MainNavbar from "./components/navbar/MainNavbar";
import { ROUTES } from "./constants/routeConstants";
import GuideTopicsPage from "./pages/VideoGuides/GuideTopicsPage";
import GuideTopicPage from "./pages/VideoGuides/GuideTopicPage";
import GuideVideoPage from "./pages/VideoGuides/GuideVideoPage";
import AlertUserMessage from "./components/commons/AlertUserMessage/AlertUserMessage";
import CommunicationPage from "./pages/communication/CommunicationPage";

interface IChildren {
  children: JSX.Element;
}

function PrivateRoute({ children }: IChildren) {
  const auth = useAuth();
  return auth ? (
    <div className="position-relative">
      <AlertUserMessage/>
      <MainNavbar />
      <div style={{ marginTop: "60px" }}>{children}</div>
    </div>
  ) : (
    <Navigate to={ROUTES.login} />
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to={ROUTES.dashboard} />} />
        <Route
          path={ROUTES.dashboard}
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.customer}
          element={
            <PrivateRoute>
              <Customer />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.GuideTopicsPage}
          element={
            <PrivateRoute>
              <GuideTopicsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.GuideTopicPage + "/:topicId"}
          element={
            <PrivateRoute>
              <GuideTopicPage />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.GuideTopicPage + "/:topicId" + ROUTES.GuideVideoPage}
          element={
            <PrivateRoute>
              <GuideVideoPage />
            </PrivateRoute>
          }
        />
        <Route
          path={
            ROUTES.GuideTopicPage +
            "/:topicId" +
            ROUTES.GuideVideoPage +
            "/:videoId"
          }
          element={
            <PrivateRoute>
              <GuideVideoPage />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.CommunicationPage}
          element={
            <PrivateRoute>
              <CommunicationPage />
            </PrivateRoute>
          }
        />
        <Route path={ROUTES.login} element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
