import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { getLastCreatedAsset } from "../../../../services/getLastCreatedAsset";
import renderColoredSpan from "../../../../utils/renderColoredSpan";

interface Props {
  campaignId: string;
}

const LastAssetCampaign = ({ campaignId }: Props) => {
  const [lastAsset, setLastAsset] = useState<Date | string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (campaignId) {
      getLastCreatedAsset(
        "getDateForLastCreatedAssetPerCampaign",
        campaignId
      ).then((res) => {
        if (res?.data?.createdAt) {
          const created = new Date(res.data.createdAt._seconds * 1000);
          setLastAsset(created);
          setIsLoading(false);
        } else {
          setLastAsset("");
          setIsLoading(false);
        }
      });
    }
  }, [campaignId]);

  return isLoading ? (
    <Spinner animation="border" size="sm" />
  ) : (
    renderColoredSpan(lastAsset)
  );
};

export default LastAssetCampaign;
