import {
  addDoc,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  collection,
  deleteField,
  query,
  orderBy,
  deleteDoc,
} from "firebase/firestore"
import { getStorage, ref, deleteObject } from "firebase/storage"

import { database, db } from "../firebase"
import { GuideTopic } from "../types/GuideTopic"
import withoutProperty from "../utils/withoutProperty"
import { GuideVideo } from "../types/GuideVideo"
import {
  GUIDE_VIDEO_STORAGE_PATH,
  GUIDE_VIDEO_THUMBNAIL_STORAGE_PATH,
  THUMBNAIL_SIZE_PREFIX,
} from "../constants/globalConstants"
import { original } from "@reduxjs/toolkit"

const storage = getStorage()

export const fetchGuides = async (): Promise<GuideTopic[]> => {
  let guides: GuideTopic[] = []
  // Get all guides from firestore

  const q = query(database.guides, orderBy("order"))
  const querySnapshot = await getDocs(q)

  querySnapshot.forEach(guideDoc => {
    // Format
    const guide = database.formatDoc(guideDoc) as GuideTopic
    guides.push(guide)
  })

  const promises = guides.map(async guide => {
    let videos: GuideVideo[] = []
    const qv = query(
      collection(db, "guides", guide.id, "videos"),
      orderBy("order")
    )
    const videoQuerySnapshot = await getDocs(qv)

    videoQuerySnapshot.forEach(videoDoc => {
      // doc.data() is never undefined for query doc snapshots
      videos.push(database.formatDoc(videoDoc) as GuideVideo)
    })
    guide["videos"] = videos
    return guide
  })

  return await Promise.all(promises)
}

/**
 *
 * @param guideId Guide id
 * @returns List of all guide videos for the specific guide
 */
export const fetchGuideVideos = async (
  guideId: string
): Promise<GuideVideo[]> => {
  let videos: GuideVideo[] = []
  const videoQuerySnapshot = await getDocs(
    collection(db, "guides", guideId, "videos")
  )
  videoQuerySnapshot.forEach(videoDoc => {
    // doc.data() is never undefined for query doc snapshots
    videos.push(database.formatDoc(videoDoc) as GuideVideo)
  })
  return videos
}

export interface newGuideDoc {
  title?: string
  description?: string
  order: number
}

export const createNewGuideInDB = async (
  newGuideDoc: newGuideDoc
): Promise<GuideTopic> => {
  const doc = {
    ...newGuideDoc,
    createdAt: database.getCurrentTimestamp(),
    published: false,
  }

  // Add GuideTopic to firestore
  const docRef = await addDoc(database.guides, doc)

  return { ...doc, id: docRef.id } as GuideTopic
}

export const updateGuideInDB = async (guide: GuideTopic): Promise<void> => {
  const guideRef = doc(database.guides, guide.id)

  const guideWithoutId = withoutProperty(guide, "id")
  const cleanGuide = withoutProperty(guideWithoutId, "videos")

  return await updateDoc(guideRef, cleanGuide)
}

export type newGuideVideoDoc = Omit<GuideVideo, "id" | "createdAt">

export const createNewGuideVideoInDB = async (
  guideId: string,
  newGuidVideoDoc: newGuideVideoDoc
): Promise<GuideVideo> => {
  const doc = {
    ...newGuidVideoDoc,
    createdAt: database.getCurrentTimestamp(),
  }

  // Add Guide video to firestore
  const docRef = await addDoc(collection(db, "guides", guideId, "videos"), doc)

  return { ...doc, id: docRef.id } as GuideVideo
}

export const updateGuideVideoInDB = async (
  guideId: string,
  guideVideo: GuideVideo
): Promise<void> => {
  console.log(guideId)
  console.log(guideVideo)
  const guideVideoRef = doc(database.guides, guideId, "videos", guideVideo.id)

  const guideVideoWithoutId = withoutProperty(guideVideo, "id")

  return await updateDoc(guideVideoRef, guideVideoWithoutId).catch(err =>
    console.log("oops", err)
  )
}
export const deleteThumbnail = async (
  guideId: string,
  guideVideo: GuideVideo
): Promise<void> => {
  console.log(guideId)
  console.log(guideVideo)
  const guideVideoRef = doc(database.guides, guideId, "videos", guideVideo.id)

  // TODO: Måste också ta bort filen på storage

  const guideVideoDocSnap = await getDoc(guideVideoRef)
  if (guideVideoDocSnap.exists()) {
    const guideVideoDoc = database.formatDoc(guideVideoDocSnap) as GuideVideo
    if (guideVideoDoc.thumbnail?.filename) {
      // Delete the file
      await deleteThumbnailFromStorage(guideVideoDoc?.thumbnail?.filename)
    }
  }

  return await updateDoc(guideVideoRef, {
    thumbnail: deleteField(),
  }).catch(err => console.log("oops", err))
}

export const deleteGuideTopic = async (guideTopicId: string): Promise<void> => {
  console.log(guideTopicId)

  if (!guideTopicId) return

  const guideRef = doc(database.guides, guideTopicId)

  return await deleteDoc(guideRef).catch(err => console.log("oops", err))
}

export const deleteGuideTopicVideo = async (
  guideTopicId: string,
  guideTopicVideoId: string
): Promise<void> => {
  if (!guideTopicId) return
  if (!guideTopicVideoId) return

  const guideVideoRef = doc(
    database.guides,
    guideTopicId,
    "videos",
    guideTopicVideoId
  )

  // get guideVideoDoc
  const guideVideoDocSnap = await getDoc(guideVideoRef)

  if (!guideVideoDocSnap.exists()) return

  const guideVideoDoc = database.formatDoc(guideVideoDocSnap) as GuideVideo

  // 1) delete thumbnail from storage
  // Create a reference to the file to delete
  if (guideVideoDoc.thumbnail?.filename) {
    // Delete the file
    await deleteThumbnailFromStorage(guideVideoDoc?.thumbnail?.filename)
  }
  // 2) Delete video from storage
  if (guideVideoDoc.video) {
    // Delete the file
    try {
      const videoRef = ref(
        storage,
        GUIDE_VIDEO_STORAGE_PATH + guideVideoDoc.video?.filename
      )
      console.log(
        "Deleting video ",
        GUIDE_VIDEO_STORAGE_PATH + guideVideoDoc.video?.filename
      )
      console.log(videoRef)

      await deleteObject(videoRef)
      console.log("Video deleted successfully")
    } catch (err) {
      console.log(err)
    }
  }
  // 3) ta bort guideVideoDoc från firestore
  return await deleteDoc(guideVideoRef).catch(err => console.log("oops", err))
}

const deleteThumbnailFromStorage = async (filename: string) => {
  // Delete the file

  const primises = (
    Object.keys(THUMBNAIL_SIZE_PREFIX) as Array<
      keyof typeof THUMBNAIL_SIZE_PREFIX
    >
  ).map(async prefix => {
    try {
      let filePath =
        prefix !== "base"
          ? GUIDE_VIDEO_THUMBNAIL_STORAGE_PATH +
            THUMBNAIL_SIZE_PREFIX[prefix] +
            filename
          : GUIDE_VIDEO_THUMBNAIL_STORAGE_PATH + filename

      const thumbnailRef = ref(storage, filePath)
      console.log("Deleting thumbnail ", filePath)

      let promise = await deleteObject(thumbnailRef)
      console.log(`Successfully deleted thumbnail ${filePath} )`)
      return promise
    } catch (err) {
      console.log(err)
    }
  })

  return Promise.all(primises)
}
