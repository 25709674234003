import styled, { keyframes } from "styled-components"
import variables from "../../../styles/variables"

type AlertProps = {
  variant: string
}

const slideIn = keyframes`
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0%);
    }
  
`

export const AlertUserMessagewrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  z-index: 9999;
`
export const AlertUserMessageContainer = styled.div`
  position: fixed;
  top: 0;
  width: 25%;
`

export const AlertUserMessage = styled.div<AlertProps>`
  width: 100%;
  padding: 10px;
  text-align: center;
  background-color: ${p =>
    p.variant === "success" ? variables.reelerLight : variables.reelerDanger};
  font-weight: bold;
  animation: ${slideIn} 500ms;
  z-index: 999;
  box-shadow: 1px 4px 7px grey;
`
