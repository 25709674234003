import { UserMessage } from "../types/UserMessage"
import { useAppDispatch } from "../redux/hooks"
import { clearMessage, showMessage } from "../redux/alertUserMessage"

export const useAlertUserMessage = () => {
  const dispatch = useAppDispatch()

  const setAlertMessage = (userMessage: UserMessage) => {
    dispatch(showMessage(userMessage))
  }

  const clearAlertMessage = () => {
    dispatch(clearMessage())
  }

  return { clearAlertMessage, setAlertMessage }
}
