import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsCalendarDate } from "react-icons/bs";

interface Props {
  integrations?: {
    instagram: {
      page_name: string;
      createdAt: {
        _seconds: number;
        _nanoseconds: number;
      };
    };
  };
}

const InstagramConnection = (props: Props) => {
  if (
    props?.integrations?.instagram?.createdAt &&
    props.integrations.instagram.createdAt._seconds
  ) {
    const created = new Date(
      props.integrations.instagram.createdAt._seconds * 1000
    );

    const renderToolTip = (props: any) => (
      <Tooltip id="tooltip" {...props}>
        Verified <br />
        {created.toLocaleDateString("sv-SE")}
      </Tooltip>
    );

    return (
      <OverlayTrigger placement="top" overlay={renderToolTip}>
        <span>
          {props?.integrations?.instagram &&
            props.integrations.instagram.page_name}{" "}
          <BsCalendarDate fontSize={12} className="ms-1" />
        </span>
      </OverlayTrigger>
    );
  } else {
    return (
      <span>
        {props?.integrations?.instagram &&
          props.integrations.instagram.page_name}
      </span>
    );
  }
};

export default InstagramConnection;
