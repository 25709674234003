import React, { FormEvent, useEffect, useRef, useState } from "react"
import { useAuth } from "../../contexts/AuthContext"
import { login } from "../../contexts/AuthFunctions"
import { Form, Card } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import CenteredContainer from "../commons/CenteredContainer"
import ReelerButton from "../commons/ReelerButton"
import { useAppSelector, useAppDispatch } from "../../redux/hooks"
import Footer from "../commons/Footer"
import StaticAlert from "../commons/StaticAlert"
import { updateDetails } from "../../redux/auth"

export const Login = () => {
  const emailRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const auth = useAppSelector(state => state.auth)
  const user = useAuth()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      setIsLoading(true)
      await login(emailRef.current!.value, passwordRef.current!.value)
    } catch {
      dispatch(
        updateDetails({
          user: {
            id: "",
            role: "",
            email: "",
          },
          msg: "Failed to login",
        })
      )
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (user && auth.user.id !== "") {
      navigate("/")
    }
  }, [auth, user, navigate])

  return (
    <>
      <CenteredContainer>
        <h1 className="text-center mb-5 logo">Reeler</h1>
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">Login</h2>
            {auth.msg && <StaticAlert text={auth.msg} />}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  ref={emailRef}
                  required
                  maxLength={48}
                />
              </Form.Group>
              <Form.Group id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordRef}
                  required
                  maxLength={48}
                />
              </Form.Group>
              <ReelerButton
                text="Login"
                disabled={isLoading}
                styleClass="btn-main w-100 mt-3"
                type="submit"
              />
            </Form>
          </Card.Body>
        </Card>
      </CenteredContainer>
      <Footer></Footer>
    </>
  )
}
