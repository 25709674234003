import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

interface IcountData {
  accountId?: string;
  data?: {
    noOfAssetsLastMonth: number;
    noOfAssetsYesterday: number;
  };
}

export const getCount = async (
  value: string,
  value2?: string
): Promise<IcountData | undefined> => {
  const getData = httpsCallable<IcountData, undefined>(functions, value);

  try {
    const result = (await getData({
      accountId: value2 ? value2 : "",
    })) as IcountData;
    return result;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};
