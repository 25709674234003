import React, { useMemo, useState } from "react"
import { AiOutlineSortAscending } from "react-icons/ai"
import { BsSortDown } from "react-icons/bs"
import {
  sortFeedAssets,
  sortFeedByDate,
  sortFeedByName,
} from "../../../../utils/sort"
import { IFeeds, selectData } from "../../../../redux/data"
import { useAppSelector } from "../../../../redux/hooks"
import * as S from "../../../../styles/styles"
import { selectActiveAccount } from "../../../../redux/activeAccount"

export const CustomerFeedsTable = () => {
  const { feeds } = useAppSelector(selectData)
  const activeAccount = useAppSelector(selectActiveAccount)

  const filteredFeeds = useMemo(() => {
    const result =
      feeds &&
      feeds.filter(feed => {
        if (activeAccount.id === feed.accountId) {
          return feed
        } else return undefined
      })

    return result
  }, [feeds, activeAccount])

  const [filtFeed, setFiltFeed] = useState<IFeeds[]>([...filteredFeeds])

  return (
    <S.TableContainer maxHeight="400px" minHeight="82px">
      <S.StyledTable>
        <S.TableHead>
          <tr>
            <th
              scope="col"
              className="col-4 pointLink"
              onClick={() => {
                const tempFeed = sortFeedByName(filteredFeeds)
                setFiltFeed(tempFeed)
              }}
            >
              Title
              <AiOutlineSortAscending
                className="ms-1"
                style={{ fontSize: "20px" }}
              />
            </th>
            <th
              scope="col"
              className="col-2 pointLink"
              onClick={() => {
                const tempFeed = sortFeedAssets(filteredFeeds)
                setFiltFeed(tempFeed)
              }}
            >
              Assets
              <BsSortDown className="ms-1" style={{ fontSize: "20px" }} />
            </th>
            <th scope="col" className="col-2">
              Type
            </th>
            <th
              scope="col"
              className="col-2 pointLink"
              onClick={() => {
                const tempFeed = sortFeedByDate(filteredFeeds)
                setFiltFeed(tempFeed)
              }}
            >
              Created
              <BsSortDown className="ms-1" style={{ fontSize: "20px" }} />
            </th>
          </tr>
        </S.TableHead>

        {filtFeed.length !== 0 ? (
          <tbody>
            {filtFeed.map(feed => {
              const createdAt = new Date(feed.createdAt._seconds * 1000)
              return (
                <tr className="fw-normal" key={feed.id}>
                  <th className="align-middle">
                    <span className="ms-2">{feed.feedName}</span>
                  </th>
                  <td className="align-middle">
                    <span>{!feed.assetCount ? 0 : feed.assetCount}</span>
                  </td>
                  <td className="align-middle">
                    <span>{feed.feedType}</span>
                  </td>
                  <td className="align-middle">
                    <span>
                      {createdAt.toLocaleString("sv-SE").substring(0, 10)}
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        ) : (
          <tbody>
            <tr className="fw-normal">
              <th>
                <span className="ms-2">(No Feeds)</span>
              </th>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        )}
      </S.StyledTable>
    </S.TableContainer>
  )
}
