import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

interface shortCampaign {
  campaignId?: string;
  data?: {
    noOfAssets: number;
  };
}

export const getNumberOfAssetsInCampaign = async (
  campaignId: string,
): Promise<shortCampaign | undefined> => {
  const getData = httpsCallable<shortCampaign, undefined>(functions, "CountNumberOfAssetsForCampaign");

  try {
    const result = (await getData({
      campaignId
    })) as shortCampaign;
    return result;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};
