import React from "react"
import { Spinner } from "react-bootstrap"

interface Props {
  spinnerClass?: string
}

export default function SpinnerComponent({ spinnerClass }: Props) {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      // style={{ height: "350px" }}
    >
      <Spinner
        animation="border"
        size="sm"
        role="status"
        className={"spinner-color " + spinnerClass}
      ></Spinner>
    </div>
  )
}
