import { FC } from "react"
import variables from "./variables"
import { Tabs } from "react-bootstrap"
import styled from "styled-components"
interface TableContainerProps {
  className?: string
  height?: string
  width?: string
  maxWidth?: string
  maxHeight?: string
  minHeight?: string
}

interface SmallTableProps {
  className?: string
  children: JSX.Element[]
}

const SmallTableTemplate: FC<SmallTableProps> = props => {
  return <div className="col-xl-6 col-12">{props.children}</div>
}

const TableTemplate: FC<SmallTableProps> = props => {
  return <table className="table table-hover mb-0">{props.children}</table>
}

export const TableContainer = styled.div<TableContainerProps>`
  overflow-x: auto;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 10px;
  height: ${props => props.height};
  width: ${props => props.width};
  max-height: ${props => props.maxHeight};
  max-width: ${props => props.maxWidth};
  min-height: ${props => props.minHeight};

  @media (min-width: 1740px) {
    margin: auto;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
`

export const BigTableContainer = styled.div`
  margin: 0 auto;
  max-width: 1800px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3rem;
  padding: 1.5rem 0;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
`
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
`

export const SmallTableContainer = styled.div<SmallTableProps>`
  display: flex;
  flex-direction: column;
  max-width: 1800px;
  margin: 0 auto;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`

export const TableHead = styled.thead`
  background-color: ${variables.reelerBlack};
  color: ${variables.reelerLight};
  position: sticky;
  top: 0;
  z-index: 1;
`

export const SmallTable = styled(SmallTableTemplate)`
  overflow-x: auto;
`

export const SmallTableTitle = styled.h5`
  margin-top: 1rem;
  padding-top: 1rem;
  margin-left: 2rem;
`

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 1.25rem;
  max-width: 1800px;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    width: 60%;
  }
`

export const IconContainer = styled.span`
  height: 24px;
  width: 24px;
  color: var(--reeler-icon);
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border-radius: 50%;
    background: var(--reelerLightGrey);
    cursor: pointer;
  }
`

export const TitleContainer = styled.h3``

export const BigTableTitle = styled.h5`
  padding-top: 0.25rem;
  margin-left: 2rem;
`

export const StyledTable = styled(TableTemplate)`
  width: 100%;
`

export const TruncatedText = styled.span`
  display: block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ReelerTab = styled.div<{ active?: boolean }>`
  background: white;
  border-radius: 3px;
  padding: 0.225rem;
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  color: ${props => (props.active ? "Yellow" : "red")};
`

export const ReelerTabs = styled(Tabs)`
  margin: 0;
`

export const StyledTopicContainer = styled.div``

export const GrabIcon = styled.div<{ isDragging?: boolean; hovered?: boolean }>`
  color: ${props =>
    props.isDragging || props.hovered ? "black" : "lightgrey"};

  &:hover {
    color: black;
  }
`
