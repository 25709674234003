import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getDateCampaignType } from "../../../../services/getDateCampaignType";
import renderColoredSpan from "../../../../utils/renderColoredSpan";

interface Props {
  accountId: string;
}

const HashtagContent = ({ accountId }: Props) => {
  const [lastAsset, setLastAsset] = useState<Date | string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getDateCampaignType(accountId, ["UPLOAD", "upload_2"]).then((res) => {
      if (res?.data?.createdAt) {
        setLastAsset(new Date(res.data.createdAt._seconds * 1000));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  }, [accountId]);

  return isLoading ? (
    <Spinner animation="border" size="sm" />
  ) : (
    renderColoredSpan(lastAsset)
  );
};

export default HashtagContent;
