import { httpsCallable } from "firebase/functions"
import { functions } from "../firebase"

export const deleteAccount = async (accountId: string) => {
  const deleteAccount = httpsCallable(functions, "deleteAccount")

  try {
    const res = await deleteAccount({
      accountId: accountId,
    })
    return res
  } catch (err) {
    console.error(err)
    return undefined
  }
}
