import React, { useState, useEffect } from "react"

//Redux
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks"
import { selectActiveAccount } from "../../../../redux/activeAccount"
import { deleteAccountInData, selectData } from "../../../../redux/data"
// Third party
import { Spinner, Button, Modal } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
// Reeler
import { deleteAccount } from "../../../../services/deleteAccount"
import { ROUTES } from "../../../../constants/routeConstants"

const DeleteAccountButton = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [hasCampaigns, setHasCampaigns] = useState<boolean>(true)
  const [open, setOpen] = useState<boolean>(false)
  const activeAccount = useAppSelector(selectActiveAccount)
  const { campaigns } = useAppSelector(selectData)

  const handleDelete = () => {
    setLoading(true)
    deleteAccount(activeAccount.id)
    dispatch(deleteAccountInData(activeAccount.id))
    setLoading(false)
    navigate(ROUTES.dashboard)
  }

  useEffect(() => {
    const filteredCampaigns =
      campaigns &&
      campaigns.filter(campaign => activeAccount.id === campaign.accountId)

    if (filteredCampaigns.length > 0) {
      setHasCampaigns(true)
    } else {
      setHasCampaigns(false)
    }
  }, [activeAccount, campaigns])

  return loading ? (
    <Spinner animation="border" size="sm" />
  ) : (
    <>
      {activeAccount.id ? (
        <Button
          variant="secondary"
          onClick={() => setOpen(true)}
          disabled={hasCampaigns}
        >
          Delete account
        </Button>
      ) : null}
      <Modal show={open} onHide={() => setOpen(false)}>
        <Modal.Body>Are you sure you want to delete this account?</Modal.Body>
        <Modal.Footer style={{ display: "flex", flexDirection: "row" }}>
          <Button onClick={() => setOpen(false)} variant="secondary">
            Close
          </Button>
          <Button onClick={handleDelete} variant="danger">
            Delete account
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeleteAccountButton
