import { DocumentData, getDocs, query } from "firebase/firestore";
import { database } from "../firebase";
import { ICampaigns } from "../redux/data";

export const getCampaigns = async () => {
  const querySnapshot = await getDocs(query(database.campaigns));
  let campaigns: DocumentData[] = [];

  querySnapshot.forEach((doc) => {
    campaigns.push({ id: doc.id, ...doc.data() });
    // doc.data() is never undefined for query doc snapshots
  });
  return campaigns as ICampaigns[];
};
