import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import * as S from "../../../styles/styles"
import CreateGuideTopic from "../components/CreateGuideTopic"
import { FaEdit, FaEyeSlash, FaLink, FaRegEye, FaGripVertical, FaTrash } from "react-icons/fa"
import variables from "../../../styles/variables"
import SpinnerComponent from "../../../components/commons/SpinnerComponent"
import { fetchGuides } from "../../../services/GuideServices"
import { StrictModeDroppable as Droppable } from "./helpersDnD/strictModeDroppable"

import {
  selectTopics,
  updateTopics,
  selectTopicById,
  setTopicToBeDeleted,
  loadTopics,
} from "../../../redux/Guides"

import { useNavigate } from "react-router-dom"
import { Container } from "react-bootstrap"
import DeleteGuideTopics from "./components/DeleteGuideTopics"
import { GuideTopic } from "../../../types/GuideTopic"

import {
  DragDropContext,
  Draggable,
  DropResult,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd"

import { updateGuideInDB } from "../../../services/GuideServices"
import { reorderArrayItem } from "../../../utils/arrayUtils"
import copyToClipboard from "../../../utils/CopyToClipbord"
import { REELER_APP_HELP_URL } from "../../../constants/globalConstants"
import ReelerIconV2 from "../../../components/commons/ReelerIconV2"
import { showMessage } from "../../../redux/alertUserMessage"

const GuideTopicsPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const topics = useAppSelector(selectTopics)
  const [loading, setLoading] = useState<boolean>(true)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchGuideTopics = async () => {
      const res = await fetchGuides()
      dispatch(loadTopics(res))
      setLoading(false)
    }

    fetchGuideTopics()
  }, [])

  const handleTopicClick = (topicId: string) => {
    dispatch(selectTopicById(topicId))
    navigate(`/topic/${topicId}`)
  }

  const handleDeleteClick = (topic: GuideTopic) => {
    dispatch(setTopicToBeDeleted(topic))
    dispatch(showMessage({
      message:"Delete successfull",
      variant: "success",
    }))
  }

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const reorderedTopics: GuideTopic[] = reorderArrayItem(
      topics,
      result.source.index,
      result.destination.index
    )

    //Loopa igenom med map för att skapa en ny array, där index blir med order.
    const updatedTopics = reorderedTopics.map((item, index) => {
      return { ...item, order: index } as GuideTopic
    })
    // Update all topics in redux
    dispatch(updateTopics(updatedTopics))

    // Update each Topic in DB
    const promises = updatedTopics.map(async item => {
      return await updateGuideInDB(item)
    })

    Promise.all(promises)
      .then(() => {
        console.log("Saved topics to DB:", updatedTopics)
      })
      .catch(err => {
        console.log(err)
      })
  }
  const [hoveredItemId, setHoveredItemId] = useState<string | null>(null)

  const handleMouseEnter = (itemId: string) => {
    setHoveredItemId(itemId)
  }

  const handleMouseLeave = () => {
    setHoveredItemId(null)
  }

  const shareTopic = (topicId: string) => {
    copyToClipboard(REELER_APP_HELP_URL + topicId)
    dispatch(showMessage({
      message:"Link has been copied",
      variant: "success",
    }))
  }

  return (
    <>
      <S.Header>
        <S.TitleContainer>Guide Topics</S.TitleContainer>
        <CreateGuideTopic />
      </S.Header>
      <DragDropContext onDragEnd={handleDragEnd}>
        <S.BigTableContainer>
          <S.TableContainer maxHeight="100%" minHeight="82px">
            <S.StyledTable>
              <S.TableHead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Topic</th>
                  <th scope="col">Description</th>

                  <th scope="col">
                    <div className="d-flex justify-content-center">
                      Quantity
                    </div>
                  </th>
                  <th scope="col">
                    <div className="d-flex justify-content-center">
                      Visability
                    </div>
                  </th>
                  <th scope="col"></th>
                </tr>
              </S.TableHead>
              <Droppable droppableId="topics">
                {(provided: DroppableProvided) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {loading ? (
                      <tr>
                        <td colSpan={5} className="text-center">
                          <div className="w-100">
                            <SpinnerComponent />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      topics.map((topic, index) => (
                        <Draggable
                          key={topic.id}
                          draggableId={topic.id}
                          index={index}
                        >
                          {(
                            provided: DraggableProvided,
                            snapshot: DraggableStateSnapshot
                          ) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={{
                                ...provided.draggableProps.style,
                                backgroundColor: snapshot.isDragging
                                  ? "lightblue"
                                  : "white",
                              }}
                              onMouseEnter={() => handleMouseEnter(topic.id)}
                              onMouseLeave={handleMouseLeave}
                            >
                              <td
                                style={{ width: 80 }}
                                {...provided.dragHandleProps}
                              >
                                <S.GrabIcon
                                  isDragging={snapshot.isDragging}
                                  hovered={hoveredItemId === topic.id}
                                >
                                  <FaGripVertical />
                                </S.GrabIcon>
                              </td>
                              <th>{topic.title}</th>
                              <td>
                                <S.TruncatedText>
                                  {topic?.description}
                                </S.TruncatedText>
                              </td>
                              <td>
                                <div className="d-flex justify-content-center">
                                  {topic.videos?.length}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex justify-content-center">
                                  <div className="align-items-center">
                                    {topic.published ? (
                                      <FaRegEye color={`${variables.reeler}`} />
                                    ) : (
                                      <FaEyeSlash
                                        color={`${variables.reelerDanger}`}
                                      />
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <Container className="d-flex justify-content-between align-items-center">
                                  <ReelerIconV2
                                    onClick={() => handleTopicClick(topic.id)}
                                    tooltip="Edit"
                                  >
                                    <FaEdit
                                      color={`${variables.reeler}`}
                                      size={17}
                                    />
                                  </ReelerIconV2>
                                  <ReelerIconV2
                                    tooltip="Copy Link"
                                    onClick={() => shareTopic(topic.id)}
                                  >
                                    <FaLink size={14} />
                                  </ReelerIconV2>
                                  <ReelerIconV2
                                    tooltip="Delete"
                                    onClick={() => handleDeleteClick(topic)}
                                    disabled={topic?.videos && topic?.videos?.length > 0}
                                  >
                                    <FaTrash />
                                  </ReelerIconV2>
                                </Container>
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))
                    )}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </S.StyledTable>
          </S.TableContainer>
        </S.BigTableContainer>
      </DragDropContext>
      <DeleteGuideTopics />
    </>
  )
}

export default GuideTopicsPage
