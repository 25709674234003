import { ICampaigns } from "../redux/data";

export function searchbarCampaign(
  filteredCampaigns: ICampaigns[],
  searchText: string
) {
  const tempCamp = [...filteredCampaigns];

  const searchList =
    tempCamp &&
    tempCamp.filter((camp) => {
      if (camp.campaignName.toLowerCase().includes(searchText.toLowerCase())) {
        return camp;
      }
      if (searchText === "" || searchText === "Search...") return camp;
      else return undefined;
    });

  const result =
    searchList &&
    searchList.filter((camp) => {
      return camp !== undefined;
    });

  return result;
}
