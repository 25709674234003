import { IAccounts } from "../redux/data";

export function searchbarDashboard(accounts: IAccounts[], searchText: string) {
  const tempCamp = [...accounts];

  const searchList =
    tempCamp &&
    tempCamp.filter((acc) => {
      if (acc.name.toLowerCase().includes(searchText.toLowerCase())) {
        return acc;
      }
      if (searchText === "" || searchText === "Search...") return acc;
      else return undefined;
    });

  const result =
    searchList &&
    searchList.filter((acc) => {
      return acc !== undefined;
    });

  return result;
}
