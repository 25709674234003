import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import type { IAccounts } from "./data"
import { rootState } from "./store"

const initialState: { account: IAccounts } = {
  account: {
    id: "",
    userId: "",
    name: "",
    totalAssets: 0,
    plan: "",
    integrations: {
      instagram: {
        page_name: "",
        createdAt: {
          _seconds: 0,
          _nanoseconds: 0,
        },
      },
    },
    createdAt: {
      _seconds: 0,
      _nanoseconds: 0,
    },
  },
}

const activeAccountSlice = createSlice({
  name: "activeAccount",
  initialState,
  reducers: {
    updateActiveAccount(
      state,
      { payload }: PayloadAction<{ account: IAccounts }>
    ) {
      state.account = payload.account
    },
  },
})

export const { updateActiveAccount } = activeAccountSlice.actions

export const selectActiveAccount = (state: rootState) =>
  state.activeAccount.account

export default activeAccountSlice.reducer
