import React, { useEffect, useState } from "react";
import { InfoCard } from "../../../../components/commons/infocard/InfoCard";
import { selectData } from "../../../../redux/data";
import { useAppSelector } from "../../../../redux/hooks";

export const TotalAssetsLastMonth = () => {
  const [assetsLastMonth, setAssetsLastMonth] = useState<number>(0);
  const { noOfAssetsLastMonth } = useAppSelector(selectData);

  useEffect(() => {
    if (noOfAssetsLastMonth) {
      setAssetsLastMonth(noOfAssetsLastMonth);
    }
  }, [noOfAssetsLastMonth]);

  return <InfoCard label={"Assets last month"} kpi={assetsLastMonth} />;
};

export default TotalAssetsLastMonth;
