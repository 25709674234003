import { httpsCallable } from "firebase/functions"
import { functions } from "../firebase"

export const changeAccountPlan = async (accountId: string, plan: string) => {
  const changePlan = httpsCallable(functions, "updateAccount")

  try {
    const res = await changePlan({
      accountId: accountId,
      payload: { plan },
    })
    return res
  } catch (err) {
    console.error(err)
    return undefined
  }
}
