import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsCalendarDate } from "react-icons/bs";

interface Props {
  hashtag: {
    id: string;
    name: string;
    createdAt: {
      seconds: number;
      nanoseconds: number;
    };
  };
}

const HashtagConnection = ({ hashtag }: Props) => {
  if (hashtag?.createdAt && hashtag.createdAt.seconds) {
    const created = new Date(hashtag.createdAt.seconds * 1000);

    const renderToolTip = (props: any) => (
      <Tooltip id="tooltip" {...props}>
        Verified <br />
        {created.toLocaleDateString("sv-SE")}
      </Tooltip>
    );

    return (
      <OverlayTrigger placement="top" overlay={renderToolTip}>
        <span>
          {hashtag && hashtag.name}{" "}
          <BsCalendarDate fontSize={12} className="ms-1" />
        </span>
      </OverlayTrigger>
    );
  } else {
    return <span>{hashtag && hashtag.name}</span>;
  }
};

export default HashtagConnection;
