import { httpsCallable } from "firebase/functions"
import { functions } from "../firebase"

interface result {
  data?: {
    noOfAssets: number
  }
}

export const getTotalNumberOfAssets = async (): Promise<result | undefined> => {
  const getData = httpsCallable<result, undefined>(functions, "getTotalAssets")

  try {
    const result = (await getData()) as result
    return result
  } catch (err) {
    console.error(err)
    return undefined
  }
}
