import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

interface shortCampaign {
  campaignId?: string;
  data?: {
    createdAt: {
      _seconds: number;
      _nanoseconds: number;
    };
  };
}

export const getLastCreatedAsset = async (
  value: string,
  value2?: string
): Promise<shortCampaign | undefined> => {
  const getData = httpsCallable<shortCampaign, undefined>(functions, value);

  try {
    const result = (await getData({
      campaignId: value2 ? value2 : "",
    })) as shortCampaign;
    return result;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};
