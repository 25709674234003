import React, { useEffect } from "react"
import {
  clearMessage,
  selectAlertUserMessage,
} from "../../../redux/alertUserMessage"
import { useSelector } from "react-redux"
import * as S from "./AlertUserMessage.styles"
import { useAppDispatch } from "../../../redux/hooks"

const AlertUserMessage: React.FC = () => {
  const alertUserMessage = useSelector(selectAlertUserMessage)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (alertUserMessage) {
      setTimeout(() => {
        dispatch(clearMessage())
      }, 3000)
    }
  }, [alertUserMessage, dispatch])

  if (!alertUserMessage) {
    return null
  }

  return (
    <S.AlertUserMessagewrapper>
      <S.AlertUserMessageContainer>
        <S.AlertUserMessage variant={alertUserMessage.variant}>
          {alertUserMessage.message}
        </S.AlertUserMessage>
      </S.AlertUserMessageContainer>
    </S.AlertUserMessagewrapper>
  )
}

export default AlertUserMessage
