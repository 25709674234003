import React from "react";
import { InfoCard } from "../../../../components/commons/infocard/InfoCard";
import { selectActiveAccount } from "../../../../redux/activeAccount";
import { selectData } from "../../../../redux/data";
import { useAppSelector } from "../../../../redux/hooks";

export const TotalUsers = () => {
  const { users } = useAppSelector(selectData);
  const activeAccount = useAppSelector(selectActiveAccount);

  const filteredAccounts =
    users &&
    users.filter((user) => {
      if (
        user.accounts &&
        Array.isArray(user.accounts) &&
        user.accounts.includes(activeAccount.id)
      ) {
        return user;
      } else return undefined;
    });

  return <InfoCard label={"Users"} kpi={filteredAccounts?.length} />;
};

export default TotalUsers;
