import moment from "moment";

const renderColoredSpan = (latestActivity: Date | string) => {
  const today = moment();
  const diff = today.diff(latestActivity, "days");
  let spanClass = "";

  if (diff >= 14) {
    spanClass = "yellowDate";
  }
  if (diff >= 60) {
    spanClass = "redDate";
  }

  return (
    <span className={spanClass}>
      {latestActivity &&
        latestActivity.toLocaleString("sv-SE").substring(0, 10)}
    </span>
  );
};

export default renderColoredSpan;
