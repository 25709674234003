import { httpsCallable } from "firebase/functions"
import { functions } from "../firebase"

export const handleDeleteCampaign = async (campaignId: string) => {
  const handleDeleteCampaign = httpsCallable(functions, "handleDeleteCampaign")

  try {
    const res = await handleDeleteCampaign({
      campaignId: campaignId,
    })
    return res
  } catch (err) {
    console.error(err)
    return undefined
  }
}
