import React, { MouseEventHandler } from "react"
import { Button } from "react-bootstrap"
import SpinnerComponent from "./SpinnerComponent"

interface Props {
  loading?: boolean
  disabled?: boolean
  dispatch?: MouseEventHandler<HTMLButtonElement>
  type?: "button" | "submit" | "reset"
  text: string
  styleClass?: string
  spinnerClass?: string
  spinnerColor?: string
}

export default function ReelerButton({
  loading,
  disabled,
  dispatch,
  type = "button",
  text = "",
  styleClass = "btn-main",
  spinnerClass,
}: Props) {
  return (
    <Button
      type={type}
      disabled={loading || disabled}
      onClick={dispatch}
      className={styleClass}
    >
      <div className="d-flex align-items-center flex-row justify-content-center">
        <span>{text}</span>

        {loading ? <SpinnerComponent spinnerClass={spinnerClass} /> : null}
      </div>
    </Button>
  )
}
