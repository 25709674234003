import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { rootState } from "./store"
import { EmailTemplate } from "../types/EmailTemplate"

export interface CommunicationState {
  emailTemplates: EmailTemplate[]
  selectedEmailTemplate: EmailTemplate | null
  templateToBeDeleted: EmailTemplate | null
}

// Initial state when starting application
const initialState: CommunicationState = {
  emailTemplates: [],
  selectedEmailTemplate: null,
  templateToBeDeleted: null,
}

// Alla funktioner för att uppdatera state, payload är nya värdet på det man vill uppdatera state med
const communicationSlice = createSlice({
  name: "communication",
  initialState,
  reducers: {
    initilizeEmailTemplates(state,{ payload }: PayloadAction<EmailTemplate[]>
    ) {
      state.emailTemplates = payload
      state.selectedEmailTemplate = null
    },
    updateEmailTemplates(state, { payload }: PayloadAction<EmailTemplate>) {
      state.emailTemplates = state.emailTemplates.map(emailTemplate =>
        emailTemplate?.id === payload?.id ? payload : emailTemplate
      )
    },
    addNewEmailTemplate(state, { payload }: PayloadAction<EmailTemplate>) {
      state.emailTemplates = [...state.emailTemplates, payload]
      state.selectedEmailTemplate = payload
    },
    deletEmailTemplate(state, { payload }: PayloadAction<EmailTemplate>) {
      state.emailTemplates = state.emailTemplates.filter(
        emailTemplate => emailTemplate.id !== payload.id
      )
      state.selectedEmailTemplate = null
    },
    setEmailTemplate(state, { payload }: PayloadAction<EmailTemplate | null>) {
      state.selectedEmailTemplate = payload
    },
    setEmailToBeDeleted(state, { payload }: PayloadAction<EmailTemplate | null>) {
      state.templateToBeDeleted = payload
    },

    closeEmailTemplate(state) {
      state.selectedEmailTemplate = null
    },
  },
})

// Exportera funktionerna för att uppdatera state så de är tillgängliga i komponenter
export const {
  initilizeEmailTemplates,
  updateEmailTemplates,
  addNewEmailTemplate,
  deletEmailTemplate,
  setEmailTemplate,
  closeEmailTemplate,
  setEmailToBeDeleted,
} = communicationSlice.actions

// Funktioer för att hämta information från state. Namnet bör börja med select så man vet att det är en selector funktion
export const selectEmailTemplates = (state: rootState) =>
  state.communication.emailTemplates
export const selectedEmailTemplate = (state: rootState) =>
  state.communication.selectedEmailTemplate
export const selectTemplateToBeDeleted = (state: rootState) =>
  state.communication.templateToBeDeleted

export default communicationSlice.reducer
