import {
  addDoc,
  getDocs,
  doc,
  updateDoc,
  query,
  orderBy,
  deleteDoc,
} from "firebase/firestore"

import { database } from "../firebase"
import withoutProperty from "../utils/withoutProperty"

import { EmailTemplate } from "../types/EmailTemplate"

export const fetchEmailTemplates = async (): Promise<EmailTemplate[]> => {
  let emailTemplates: EmailTemplate[] = []
  // Get all guides from firestore

  const q = query(database.emailTemplates)
  const querySnapshot = await getDocs(q)

  querySnapshot.forEach(emailTemplateDoc => {
    // Format
    const emailTemplate = database.formatDoc(emailTemplateDoc) as EmailTemplate
    emailTemplates.push(emailTemplate)
  })

  return emailTemplates
}

export interface newGuideDoc {
  title?: string
  description?: string
  order: number
}

export const createNewEmailTemplateDB = async (
  emailTemplate: EmailTemplate
): Promise<EmailTemplate> => {
  const doc = {
    ...emailTemplate,
    createdAt: database.getCurrentTimestamp(),
  }

  // Add GuideTopic to firestore
  const docRef = await addDoc(database.emailTemplates, doc)

  return { ...doc, id: docRef.id } as EmailTemplate
}

export const updateEmailTemplateDB = async (
  emailTemplate: EmailTemplate
): Promise<void> => {
  const emailTemplateRef = doc(database.emailTemplates, emailTemplate.id)

  const emailTemplateWithoutId = withoutProperty(emailTemplate, "id")

  return await updateDoc(emailTemplateRef, emailTemplateWithoutId).catch(err =>
    console.log("oops", err)
  )
}

export const deleteEmailTemplate = async (
  emailTemplateId: string
): Promise<void> => {
  if (!emailTemplateId) return

  const emailTemplateRef = doc(database.emailTemplates, emailTemplateId)

  return await deleteDoc(emailTemplateRef).catch(err =>
    console.log("oops", err)
  )
}
