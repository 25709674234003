import React from "react"
import { useAppSelector } from "../../redux/hooks"
import Footer from "../../components/commons/Footer"
import { CustomerCampaignTable } from "./components/tables/CustomerCampaignTable"
import { CustomerFeedsTable } from "./components/tables/CustomerFeedsTable"
import { CustomerUserTable } from "./components/tables/CustomerUserTable"
import { DashBoardTable } from "../dashboard/components/DashBoardTable/DashBoardTable"
import TotalAssets from "./components/infocards/TotalAssets"
import TotalUsers from "./components/infocards/TotalUsers"
import TotalCampaigns from "./components/infocards/TotalCampaigns"
import TotalFeeds from "./components/infocards/TotalFeeds"
import * as S from "../../styles/styles"
import TotalAssetsYesterday from "./components/infocards/TotalAssetsYesterday"
import TotalAssetsLastMonth from "./components/infocards/TotalAssetsLastMonth"
import { selectActiveAccount } from "../../redux/activeAccount"
import CopyIDIcon from "../../components/commons/CopyIDIcon"

import TogglePlan from "./components/toggle-plan"
import DeleteAccountButton from "./components/delete-account"
import Filter from "../dashboard/components/filter"
import ExportUsers from "../../components/commons/ExportUsers"

export const Customer = () => {
  const activeAccount = useAppSelector(selectActiveAccount)

  return activeAccount.id === "" ? (
    <div className="pt-5 mt-5 vh-100">
      <S.BigTableContainer>
        <ExportUsers accountId={""} />
        <Filter />
        <DashBoardTable />
      </S.BigTableContainer>
      <Footer />
    </div>
  ) : (
    <div className="vh-100">
      <S.Header>
        <S.TitleContainer>
          Customer -{" "}
          {activeAccount.name ? activeAccount.name : "(No name exist)"}
          {!activeAccount.id ? (
            <></>
          ) : (
            <span
              className="ms-2"
              onClick={() => {
                navigator.clipboard.writeText(activeAccount.id)
              }}
            >
              <CopyIDIcon />
            </span>
          )}
        </S.TitleContainer>
        <DeleteAccountButton />
      </S.Header>
      <TogglePlan />
      <S.CardContainer>
        <TotalAssets />
        <TotalUsers />
        <TotalCampaigns />
        <TotalFeeds />
        <TotalAssetsYesterday />
        <TotalAssetsLastMonth />
      </S.CardContainer>
      <S.BigTableContainer>
        <S.BigTableTitle>Campaigns</S.BigTableTitle>
        <CustomerCampaignTable />
      </S.BigTableContainer>
      <S.SmallTableContainer>
        <S.SmallTable>
          <S.SmallTableTitle>Users</S.SmallTableTitle>
          <CustomerUserTable />
        </S.SmallTable>
        <S.SmallTable>
          <S.SmallTableTitle>Feeds</S.SmallTableTitle>
          <CustomerFeedsTable />
        </S.SmallTable>
      </S.SmallTableContainer>
      <Footer />
    </div>
  )
}
