import React, { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import Footer from "../../components/commons/Footer"
import SpinnerComponent from "../../components/commons/SpinnerComponent"
import { DashBoardTable } from "./components/DashBoardTable/DashBoardTable"
import TotalAssets from "./components/infocards/TotalAssets"
import TotalAccounts from "./components/infocards/TotalAccounts"
import TotalCampaigns from "./components/infocards/TotalCampaigns"
import TotalFeeds from "./components/infocards/TotalFeeds"
import TotalAssetsYesterday from "./components/infocards/TotalAssetsYesterday"
import TotalAssetsLastMonth from "./components/infocards/TotalAssetsLastMonth"
import { updateActiveAccount } from "../../redux/activeAccount"
import { selectData } from "../../redux/data"
import * as S from "../../styles/styles"
import UserActivity from "./components/infocards/UserActivity"
import Filter from "./components/filter"
import SetMetadataToFalseForAllAssets from "../customer/components/SetMetadataToFalseForAllAssets"
import TestCrawlPermalink from "./TestCrawlPermalink"

export const Dashboard = () => {
  const { accounts } = useAppSelector(selectData)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      updateActiveAccount({
        account: {
          id: "",
          userId: "",
          name: "",
          plan: "",
          createdAt: {
            _seconds: 0,
            _nanoseconds: 0,
          },
        },
      })
    )
  }, [dispatch])

  return accounts.length === 0 ? (
    <div className="mt-5 pt-5">
      <SpinnerComponent />
    </div>
  ) : (
    <div>
      <S.TitleContainer>Dashboard</S.TitleContainer>
      <S.CardContainer>
        <TotalAccounts />
        <TotalCampaigns />
        <TotalAssets />
        <TotalFeeds />
        <TotalAssetsLastMonth />
        <TotalAssetsYesterday />
        {/* <UserActivity days={31} label={"MAU"} /> */}
        <UserActivity days={7} label={"Weekly Users"} />
        <UserActivity days={1} label={"Daily Users"} />
      </S.CardContainer>
      <S.BigTableContainer>
        <Filter />
        <DashBoardTable />
      </S.BigTableContainer>

      <Footer />
    </div>
  )
}
