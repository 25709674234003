import React, { useState } from "react"

// Redux
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks"
import { updateFilter, selectFilter } from "../../../../redux/filter"
import { ACCOUNT_PLANS } from "../../../../constants/globalConstants"
import * as S from "./filter-styles"
import { Form } from "react-bootstrap"

export const Filter = () => {
  const { filter } = useAppSelector(selectFilter)
  const dispatch = useAppDispatch()

  const handleChange = (plan: string) => {
    if (filter.plans.includes(plan)) {
      //Remove plan
      dispatch(updateFilter(filter.plans.filter(p => p !== plan)))
    } else {
      //Add plan
      dispatch(updateFilter([...filter.plans, plan]))
    }
  }

  return (
    <S.FilterContainer>
      <S.FilterHeading>Filter:</S.FilterHeading>
      <S.FilterOptions>
        <Form.Check
          inline
          label="Disabled"
          name="plan"
          type="checkbox"
          id="plan-disabled"
          onChange={() => handleChange(ACCOUNT_PLANS.disabled)}
          checked={filter.plans.includes(ACCOUNT_PLANS.disabled)}
        />
        <Form.Check
          inline
          label="Basic"
          name="plan"
          type="checkbox"
          id="plan-basic"
          onChange={() => handleChange(ACCOUNT_PLANS.basic)}
          checked={filter.plans.includes(ACCOUNT_PLANS.basic)}
        />
        <Form.Check
          inline
          label="Pro"
          name="plan"
          type="checkbox"
          id="plan-pro"
          onChange={() => handleChange(ACCOUNT_PLANS.pro)}
          checked={filter.plans.includes(ACCOUNT_PLANS.pro)}
        />
        <Form.Check
          inline
          label="Premium"
          name="plan"
          type="checkbox"
          id="plan-premium"
          onChange={() => handleChange(ACCOUNT_PLANS.premium)}
          checked={filter.plans.includes(ACCOUNT_PLANS.premium)}
        />
      </S.FilterOptions>
    </S.FilterContainer>
  )
}
