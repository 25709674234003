import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Notification } from "../types/Notification";
import { rootState } from "./store";


export interface NotificationState {
    notifications: Notification[]
    selectedNotification: Notification | null
}

const initialState: NotificationState = {
    notifications: [],
    selectedNotification: null
}

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        initializeNotifications(state, {payload}: PayloadAction<Notification[]>){
            state.selectedNotification = null
            state.notifications = payload

        },
        updateNotification(state, { payload }: PayloadAction<Notification>) {
            state.notifications = state.notifications.map(notification =>
              notification?.id === payload?.id ? payload : notification
            )
          },
        addNewNotification(state, {payload}: PayloadAction<Notification>){
            state.notifications = [...state.notifications, payload]
            state.selectedNotification = payload
        },
        setNotification(state, {payload}: PayloadAction<Notification | null>){
            state.selectedNotification = payload
        },
        deleteNotification(state, {payload}: PayloadAction<Notification>){
            state.notifications = state.notifications.filter(
                notification => notification.id !== payload.id
            )
            state.selectedNotification = null
        },
    }
})

export const {
    initializeNotifications,
    addNewNotification,
    setNotification,
    updateNotification,
    deleteNotification,
} 
= notificationSlice.actions

export const selectNotifications = (state: rootState) => 
    state.notification.notifications

export const selectedNotification = (state: rootState) =>
    state.notification.selectedNotification

export default notificationSlice.reducer