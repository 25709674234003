import {
  AnyAction,
  configureStore,
  Dispatch,
  Middleware,
} from "@reduxjs/toolkit"
import authReducer from "./auth"
import logger from "redux-logger"
import dataReducer from "./data"
import activeAccountReducer from "./activeAccount"
import filterAccountReducer from "./filter"
import guidesReducer from "./Guides"
import alertUserMessage from "./alertUserMessage"
import communicationReducer from "./Communication"
import notificationReducer from "./Notifications"

const middlewares: Middleware<{}, any, Dispatch<AnyAction>>[] = []

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger)
}

export const store = configureStore({
  reducer: {
    auth: authReducer,
    data: dataReducer,
    activeAccount: activeAccountReducer,
    filter: filterAccountReducer,
    guides: guidesReducer,
    alertUserMessage: alertUserMessage,
    communication: communicationReducer,
    notification: notificationReducer
  },
  middleware: middlewares,
})

export type appDispatch = typeof store.dispatch

export type rootState = ReturnType<typeof store.getState>
