import React, { useState } from "react"
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { storage } from "../../../firebase"
import { useNavigate } from "react-router-dom"
import * as S from "../../../styles/styles"
import { Form, Button, Container, Dropdown, Row, Col } from "react-bootstrap"
import ReelerButton from "../../../components/commons/ReelerButton"
import { useAppSelector, useAppDispatch } from "../../../redux/hooks"
import {
  createNewTopicVideo,
  selectSelectedTopicVideo,
  selectSelectedTopic,
  updateTopicVideo,
  updateTopicsWithSelectedTopic,
  selectSelelectedThumbnail,
  selectVideoToBeUploaded,
  setSelectedThumbnail,
  setVideoToBeUploaded,
} from "../../../redux/Guides"
import { GuideVideo } from "../../../types/GuideVideo"
import {
  createNewGuideVideoInDB,
  newGuideVideoDoc,
  updateGuideVideoInDB,
} from "../../../services/GuideServices"
import UploadVideo from "./components/UploadVideo"
import Thumbnail from "./components/Thumbnail/Thumbnail"
import {
  GUIDE_VIDEO_STORAGE_PATH,
  GUIDE_VIDEO_THUMBNAIL_STORAGE_PATH,
} from "../../../constants/globalConstants"
import { v4 as uuidv4 } from "uuid"
import ReelerAlert from "../../../components/commons/ReelerAlert"
import { UserMessage } from "../../../types/UserMessage"
import { showMessage } from "../../../redux/alertUserMessage"
import { useAlertUserMessage } from "../../../hooks/useAlertUserMessage"

export default function GuideVideoPage() {
  const dispatch = useAppDispatch()
  const selectedGuideTopic = useAppSelector(selectSelectedTopic)
  const selectedVideo = useAppSelector(selectSelectedTopicVideo)
  const selectedThumbnail = useAppSelector(selectSelelectedThumbnail)
  const videoToBeUploaded = useAppSelector(selectVideoToBeUploaded)
  const navigate = useNavigate()
  const [saving, setSaving] = useState<boolean>()
  const [msg, setMsg] = useState<UserMessage | null>(null)
  const HandleGoBackButton = () => {
    navigate(-1)
  }
  const { setAlertMessage, clearAlertMessage } = useAlertUserMessage()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    dispatch(
      updateTopicVideo({
        ...selectedVideo,
        [name]: value,
      } as GuideVideo)
    )
  }

  const handlePublish = (published: boolean) => {
    dispatch(
      updateTopicVideo({
        ...selectedVideo,
        published: published,
      } as GuideVideo)
    )
  }

  const uploadNewThumbnail = async (): Promise<
    { url: string; filename: string; original_filename: string } | undefined
  > => {
    console.log("1")
    if (!selectedThumbnail) return

    // TODO: Set a new random filename. Take extension from name
    const fileExtension = selectedThumbnail.type.split("/").pop()
    const fileName = uuidv4() + "." + fileExtension

    const storageRef = ref(
      storage,
      GUIDE_VIDEO_THUMBNAIL_STORAGE_PATH + fileName
    )
    const uploadTask = uploadBytesResumable(storageRef, selectedThumbnail)

    const promise = new Promise<
      { url: string; filename: string; original_filename: string } | undefined
    >((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        snapshot => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log("Upload is " + progress + "% done")
        },
        err => {
          console.log(err)
          reject(undefined)
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
            console.log("File available at", downloadURL)
            resolve({
              url: downloadURL,
              filename: fileName,
              original_filename: selectedThumbnail.name,
            })
          })
        }
      )
    })

    return await promise
  }

  const uploadNewVideo = async (): Promise<
    | {
        url: string
        filename: string
        original_filename: string
        size: number
        type: string
      }
    | undefined
  > => {
    console.log("1")
    if (!videoToBeUploaded) return

    const fileExtension = videoToBeUploaded.type.split("/").pop()
    const fileName = uuidv4() + "." + fileExtension

    const storageRef = ref(storage, GUIDE_VIDEO_STORAGE_PATH + fileName)
    const uploadTask = uploadBytesResumable(storageRef, videoToBeUploaded)

    const promise = new Promise<
      | {
          url: string
          filename: string
          original_filename: string
          size: number
          type: string
        }
      | undefined
    >((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        snapshot => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log("Upload is " + progress + "% done")
        },
        err => {
          console.log(err)
          reject(undefined)
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
            console.log("File available at", downloadURL)
            resolve({
              url: downloadURL,
              filename: fileName,
              original_filename: videoToBeUploaded.name,
              type: videoToBeUploaded.type,
              size: videoToBeUploaded.size,
            })
          })
        }
      )
    })

    return await promise
  }

  const handleSave = async () => {
    setSaving(true)
    setMsg(null)
    clearAlertMessage()
    if (selectedVideo?.id && selectedGuideTopic?.id) {
      // Update video
      try {
        let updatedVideo: GuideVideo = { ...selectedVideo }

        let thumbnail = null

        if (selectedThumbnail) {
          thumbnail = await uploadNewThumbnail()
          if (thumbnail) {
            updatedVideo.thumbnail = thumbnail
          }
        }

        let uploadedVideo = null
        if (videoToBeUploaded) {
          uploadedVideo = await uploadNewVideo()
          if (uploadedVideo) {
            updatedVideo.video = uploadedVideo
          }
        }

        updateGuideVideoInDB(selectedGuideTopic?.id, updatedVideo)
          .then(() => {
            // om selectedVideo.id finns så skall vi uppdatera topic, om selectedVideo.id inte finns då är det en ny
            dispatch(updateTopicVideo(updatedVideo))
            dispatch(updateTopicsWithSelectedTopic())
            dispatch(setSelectedThumbnail(null))
            dispatch(setVideoToBeUploaded(null))
            setAlertMessage({
              message: "Successfully updated!",
              variant: "success",
            })

            setSaving(false)
          })
          .catch(err => {
            console.log(err)
          })
      } catch (err) {
        console.log(err)
      }
    } else if (selectedVideo && selectedGuideTopic?.id) {
      // Create new video
      let newVideo: newGuideVideoDoc = {
        ...selectedVideo,
        order: selectedGuideTopic?.videos
          ? selectedGuideTopic?.videos?.length
          : 0,
      }
      console.log("newvideo: ", newVideo)

      try {
        let thumbnail = null

        if (selectedThumbnail) {
          thumbnail = await uploadNewThumbnail()
          if (thumbnail) {
            newVideo.thumbnail = thumbnail
          }
        }

        let uploadedVideo = null
        if (videoToBeUploaded) {
          uploadedVideo = await uploadNewVideo()
          if (uploadedVideo) {
            newVideo.video = uploadedVideo
          }
        }

        createNewGuideVideoInDB(selectedGuideTopic.id, newVideo)
          .then(guideVideo => {
            dispatch(createNewTopicVideo(guideVideo))
            dispatch(updateTopicsWithSelectedTopic())
            dispatch(setSelectedThumbnail(null))
            dispatch(setVideoToBeUploaded(null))
            // Save to database
            console.log("Uploaded new videdo", guideVideo)
            setSaving(false)
            setAlertMessage({
              message: "Successfully upploaded video!",
              variant: "success",
            })
          })
          .catch(err => {
            console.log(err)
          })
      } catch (err) {
        console.log(err)
      }
    }
  }

  return (
    <div>
      <S.Header>
        <S.TitleContainer>
          {" "}
          {selectedVideo?.id ? "Edit video" : "Upload new video"}
        </S.TitleContainer>
        <div className="d-flex justify-content-end mt-3 w-50">
          <Button
            variant="secondary"
            className="me-2"
            onClick={HandleGoBackButton}
          >
            Close
          </Button>
          <ReelerButton
            text="Save Changes"
            dispatch={handleSave}
            loading={saving}
          />
        </div>
      </S.Header>
      <Container fluid className="bg-white">
        <Row>
          <Col sm={12} md={6} className="mb-4">
            <div className="bg-white p-4">
              <Form>
                <Form.Group className="mb-3" controlId="title">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Add a video title"
                    value={selectedVideo?.title}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={10}
                    name="description"
                    placeholder="Add a description"
                    onChange={handleChange}
                    value={selectedVideo?.description}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="youtubeLink">
                  <Form.Label className="">Youtube Link</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="www.youtube.com/@reelertech"
                    name="youtubeLink"
                    value={selectedVideo?.youtubeLink}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Thumbnail />
              </Form>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div className="w-100 bg-white p-4">
              <Form.Group>
                <UploadVideo />
              </Form.Group>
              {/*progress > 0 ? <ReelerProgressBar progress={progress} /> : null}*/}
              <Form.Group>
                <Dropdown className="rounded">
                  <Dropdown.Toggle
                    variant={selectedVideo?.published ? "success" : "danger"}
                    id="dropdown-basic"
                    size="sm"
                    style={{ padding: "7px 12px" }}
                  >
                    {selectedVideo?.published ? "Published" : "Hidden"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handlePublish(false)}>
                      Unpublished
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handlePublish(true)}>
                      Published
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
