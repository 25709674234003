import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { rootState } from "./store"
import { ACCOUNT_PLANS } from "../constants/globalConstants"

// Define a type for the slice state
interface FilterState {
  plans: string[]
}

// Define the initial state using that type
const initialState: FilterState = {
  plans: [ACCOUNT_PLANS.basic, ACCOUNT_PLANS.pro, ACCOUNT_PLANS.premium],
}

const filterAccountSlice = createSlice({
  name: "filterAccounts",
  initialState,
  reducers: {
    updateFilter: (state, action: PayloadAction<string[]>) => {
      state.plans = action.payload
    },
  },
})

export const { updateFilter } = filterAccountSlice.actions

export const selectFilter = (state: rootState) => state

export default filterAccountSlice.reducer
