import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

export const getData = async <T>(value: string): Promise<T | undefined> => {
  const getData = httpsCallable<T, undefined>(functions, value);

  try {
    const result = (await getData()) as T;
    return result;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};
