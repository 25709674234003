import React, { useEffect, useState } from "react"
import variables from "../../../styles/variables"
import ReelerIconV2 from "../../../components/commons/ReelerIconV2"
import * as S from "../../../styles/styles"
import { FaEdit, FaGripVertical } from "react-icons/fa"
import { Container } from "react-bootstrap"
import ReelerButton from "../../../components/commons/ReelerButton"
import DeleteModal from "../../../components/commons/DeleteModal/DeleteModal"
import {
  deleteNotification,
  initializeNotifications,
  setNotification,
  updateNotification,
} from "../../../redux/Notifications"

import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { Notification } from "../../../types/Notification"
import {
  deleteNotificatinDB,
  fetchNotifications,
  publishNotification,
} from "../../../services/NotificationService"
import { useAlertUserMessage } from "../../../hooks/useAlertUserMessage"

interface Props {
  notification: Notification
}

const NotificationRow = ({ notification }: Props) => {
  const [publishing, setPublishing] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const { setAlertMessage } = useAppSelector(useAlertUserMessage)
  useEffect(() => {
    const loadNotifications = async () => {
      const notification = await fetchNotifications()
      dispatch(initializeNotifications(notification))
    }
    loadNotifications()
  }, [])

  const handleEditNotification = (n: Notification) => {
    dispatch(setNotification(n))
  }

  const handleDeleteNotification = async (notification: Notification) => {
    if (!notification.id) return
    try {
      await deleteNotificatinDB(notification.id)
      dispatch(deleteNotification(notification))
      setAlertMessage({
        message: "Succefully deleted",
        variant: "success",
      })
    } catch (error) {
      console.log(error)
      setAlertMessage({
        message: "Something went wrong",
        variant: "danger",
      })
    }
  }
  const handlePublishNotification = async (notification: Notification) => {
    if (!notification.id) return
    setPublishing(true)
    try {
      await publishNotification(notification.id)
      const updatedNotification = {
        ...notification,
        published: true,
      }
      dispatch(updateNotification(updatedNotification))
      setPublishing(false)
      setAlertMessage({
        message: "Succefully published notification",
        variant: "success",
      })
    } catch (error) {
      console.log(error)
      setAlertMessage({
        message: "Something went wrong when publishing notification",
        variant: "danger",
      })
    }
  }
  return (
    <tr>
      <td>
        <S.GrabIcon>
          <FaGripVertical />
        </S.GrabIcon>
      </td>
      <td>{notification?.title}</td>
      <td>
        <S.TruncatedText>{notification.message}</S.TruncatedText>
      </td>
      <td>{notification.variant}</td>
      <td>{notification.customers?.map(c => c.name).join(",")}</td>
      <td>
        <Container className="d-flex justify-content-between align-items-center">
          <ReelerIconV2
            onClick={() => handleEditNotification(notification)}
            tooltip="Edit"
          >
            <FaEdit size={17} color={`${variables.reeler}`} />
          </ReelerIconV2>
          <DeleteModal
            onDelete={() => handleDeleteNotification(notification)}
            title={`Delete ${notification.title}`}
            body={`Do you want to delete ${notification.title}`}
          />
          <ReelerButton
            text={notification.published ? "Published!" : "Publish"}
            disabled={notification.published}
            loading={publishing}
            dispatch={() => handlePublishNotification(notification)}
          />
        </Container>
      </td>
    </tr>
  )
}

export default NotificationRow
