import styled from "styled-components";
import { Link } from "react-router-dom";
import variables from "../../styles/variables";

type IProfileLinkProps = {
  danger: string;
};

export const HideWhenSmallDevice = styled.div`
  display: block;

  @media screen and (max-width: 575px) {
    display: none;
  }
`;

export const DisplayWhenSmallDevice = styled.div`
  border-top: 1px solid ${variables.reelerLink};
  padding: 10px 0;
  display: none;

  @media screen and (max-width: 575px) {
    display: block;
    margin-right: 1rem !important;
  }
`;

export const MainNavbarContainer = styled.div`
  font-size: 1rem;

  @media screen and (max-width: 575px) {
    font-size: 1.2rem;
    text-align: center;
  }
`;

export const ProfileLink = styled(Link)<IProfileLinkProps>`
  padding: 5px 0;
  color: ${({ danger }) => (danger === "true" ? "#dc3545" : "#707070")};
  &:hover {
    color: ${variables.reelerDangerLight} !important;
  }
`;

export const Email = styled.div`
  background-color: ${variables.reelerLight};
  margin-bottom: 0.5em;
  padding: 0.3em;
  border-radius: 3px 3px 0 0;
  color: ${variables.reelerBlack};
  text-align: center;
`;

export const CustomNavLink = styled.div`
  &:hover {
    color: ${variables.reeler};
  }
  @media screen and (max-width: 575px) {
    padding: 5px 0;
  }
`;
