import React, { useState, useEffect } from "react"
import { Spinner } from "react-bootstrap"
import { getTotalNumberOfAssetsForAccount } from "../../../../services/getTotalNumberOfAssetsForAccount"

interface Props {
  accountId: string
}

const NumberOfAssetsForAccount = ({ accountId }: Props) => {
  const [numberOfAssets, setNumberOfAssets] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (accountId) {
      getTotalNumberOfAssetsForAccount(accountId).then(res => {
        if (res?.data?.noOfAssets) {
          setNumberOfAssets(res?.data?.noOfAssets)
          setIsLoading(false)
        } else {
          setNumberOfAssets(0)
          setIsLoading(false)
        }
      })
    }
  }, [accountId])

  return isLoading ? (
    <Spinner animation="border" size="sm" />
  ) : (
    <span>{numberOfAssets}</span>
  )
}

export default NumberOfAssetsForAccount
