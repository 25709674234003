import React, { useEffect } from "react";
import * as S from "../../styles/styles";
import { Tab } from "react-bootstrap";
import Templates from "./components/Templates";
import Notifications from "./components/Notifications";
import { fetchEmailTemplates } from "../../services/EmailTemplateService";
import { useDispatch } from "react-redux";
import { initilizeEmailTemplates } from "../../redux/Communication";

const CommunicationPage: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const loadEmailTopics = async () => {
      const emailTemplate = await fetchEmailTemplates();

      dispatch(initilizeEmailTemplates(emailTemplate));
    };

    loadEmailTopics();
  }, []);

  

  return (
    <S.Container>
      <S.Header>
        <S.TitleContainer>Communication</S.TitleContainer>
      </S.Header>
      <S.Body>
        <S.ReelerTabs id="communication-tabs" defaultActiveKey="Templates">
          <Tab eventKey="Templates" title="Templates">
            <S.ReelerTab>
              <Templates />
            </S.ReelerTab>
          </Tab>
          <Tab eventKey="notifications" title="Notifications">
            <S.ReelerTab>
              <Notifications />
            </S.ReelerTab>
          </Tab>
        </S.ReelerTabs>
      </S.Body>
    </S.Container>
  );
};

export default CommunicationPage;
