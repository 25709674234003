import React, { useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import ReelerButton from "../../../../components/commons/ReelerButton";
import { Form, Button, Modal } from "react-bootstrap";
import { createNewTopic, selectTopics } from "../../../../redux/Guides";
import {
  createNewGuideInDB,
  newGuideDoc,
} from "../../../../services/GuideServices";
import { showMessage } from "../../../../redux/alertUserMessage";

const CreateGuideTopic: React.FC = () => {
  const dispatch = useAppDispatch();
  const topics = useAppSelector(selectTopics);

  const [show, setShow] = useState(false);

  const title = useRef<HTMLInputElement | null>(null);
  const desc = useRef<HTMLTextAreaElement | null>(null);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const handleCreateNewTopic = () => {
    dispatch(
      showMessage({
        message: "Topic saved",
        variant: "success",
      })
    );
    console.log("Title:" + title?.current?.value);
    console.log("Desc:" + desc?.current?.value);

    const newGuide: newGuideDoc = {
      title: title?.current?.value,
      description: desc?.current?.value,
      order: topics.length,
    };

    createNewGuideInDB(newGuide)
      .then((guide) => {
        // update redux with the new guide that we have saved to database
        dispatch(createNewTopic(guide));
      })
      .then(handleClose)

      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <ReelerButton dispatch={handleShow} text="Create new topic" />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add new topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                ref={title}
                type="text"
                placeholder="Add a topic title"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control ref={desc} as="textarea" rows={3} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <ReelerButton
            dispatch={() => handleCreateNewTopic()}
            text="Create new topic"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateGuideTopic;
