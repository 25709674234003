import React from "react";
import { Container } from "react-bootstrap";

interface IChildren {
  children: JSX.Element[];
}

export default function CenteredContainer({ children }: IChildren) {
  return (
    <Container
      fluid
      className="d-flex align-items-center justify-content-center auth_background"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: "400px" }}>
        {children}
      </div>
    </Container>
  );
}
