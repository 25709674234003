import { httpsCallable } from "firebase/functions"
import { functions } from "../firebase"

interface shortAccount {
  accountId?: string
  data?: {
    noOfAssets: number
  }
}

export const getTotalNumberOfAssetsForAccount = async (
  accountId: string
): Promise<shortAccount | undefined> => {
  const getData = httpsCallable<shortAccount, undefined>(
    functions,
    "CountNumberOfAssetsForAccount"
  )

  try {
    const result = (await getData({
      accountId,
    })) as shortAccount
    return result
  } catch (err) {
    console.error(err)
    return undefined
  }
}
