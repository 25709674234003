import moment from "moment";
import React, { useEffect, useState } from "react";
import { InfoCard } from "../../../../components/commons/infocard/InfoCard";
import { selectData } from "../../../../redux/data";
import { useAppSelector } from "../../../../redux/hooks";

interface Props {
  days: number;
  label: string;
}

const UserActivity = ({ days, label }: Props) => {
  const { users } = useAppSelector(selectData);
  const [activity, setActivity] = useState<number>(0);

  useEffect(() => {
    const activeUsers = users.filter((user) => {
      if (user.latestActivity) {
        const latestActivity = new Date(user.latestActivity._seconds * 1000);
        const today = moment();
        const diff = today.diff(latestActivity, "days");

        if (diff <= days) {
          return user;
        }
      }
      return null;
    });
    setActivity(activeUsers.length);
  }, [users, days]);

  return <InfoCard label={label} kpi={activity} />;
};

export default UserActivity;
