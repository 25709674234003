import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { rootState } from "./store"
import { UserMessage } from "../types/UserMessage"

export interface AlertUserMessageState {
  alertUserMessage: UserMessage | null
}

const initialState: AlertUserMessageState = {
  alertUserMessage: null,
}

const alertUserMessageSlice = createSlice({
  name: "alertUserMessage",
  initialState,
  reducers: {
    showMessage(state, { payload }: PayloadAction<UserMessage>) {
      return {
        ...state,
        alertUserMessage: payload,
      }
    },
    clearMessage(state) {
      return {
        ...state,
        alertUserMessage: null,
      }
    },
  },
})

export const { showMessage, clearMessage } = alertUserMessageSlice.actions

export const selectAlertUserMessage = (state: rootState) =>
  state.alertUserMessage.alertUserMessage

export default alertUserMessageSlice.reducer
