import React, { useState } from "react"
import { Spinner, Button } from "react-bootstrap"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../../firebase"

interface Props {
  accountId: string | null
}

const ExportUsers = ({ accountId }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleExportUsers = async () => {
    const adminExportUsers = httpsCallable<
      { accountId: string | null },
      Object
    >(functions, "adminExportUsers")

    try {
      setIsLoading(true)
      const res = await adminExportUsers({ accountId })

      const blob = new Blob([res.data as unknown as string], {
        type: "application/json;charset=utf-8",
      })
      const jsonObjectUrl = URL.createObjectURL(blob)

      const filename = "ReelerUsers.csv"
      const anchorEl = document.createElement("a")
      anchorEl.href = jsonObjectUrl
      anchorEl.download = filename

      // There is no need to actually attach the DOM
      // element but we do need to click on it
      anchorEl.click()

      // We don't want to keep a reference to the file
      // any longer so we release it manually
      URL.revokeObjectURL(jsonObjectUrl)

      console.log(res)
      setIsLoading(false)
    } catch (err) {
      console.error(err)
      setIsLoading(false)
    }
  }

  return isLoading ? (
    <Spinner animation="border" size="sm" />
  ) : (
    <Button onClick={() => handleExportUsers()}>Export users</Button>
  )
}

export default ExportUsers
