import React from "react";
import { InfoCard } from "../../../../components/commons/infocard/InfoCard";
import { selectActiveAccount } from "../../../../redux/activeAccount";
import { selectData } from "../../../../redux/data";
import { useAppSelector } from "../../../../redux/hooks";

export const TotalCampaigns = () => {
  const { campaigns } = useAppSelector(selectData);
  const activeAccount = useAppSelector(selectActiveAccount);

  const filteredCampaigns =
    campaigns &&
    campaigns.filter((campaign) => {
      if (activeAccount.id === campaign.accountId) {
        return campaign;
      } else return undefined;
    });

  return <InfoCard label={"Campaigns"} kpi={filteredCampaigns?.length} />;
};

export default TotalCampaigns;
