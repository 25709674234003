import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import * as S from "./ReelerIconV2.styles"

interface Props {
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void
  tooltip?: string
  children?: React.ReactNode
  disabled?: boolean
}

const ReelerIconV2: React.FC<Props> = ({ onClick, tooltip, children, disabled }) => {
  const renderToolTip = (props: any) => (
    <Tooltip id="tooltip" {...props}>
      {tooltip}
    </Tooltip>
  )

  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (!disabled && onClick) {
      onClick(event)
    }
  }

  const iconStyles = {
    cursor: disabled ? "not-allowed" : "pointer",
    color: disabled ? "grey" : "inherit"
  };

  return tooltip ? (
    <OverlayTrigger
      placement="top"
      // delay={{ show: 0, hide: 100 }}
      overlay={renderToolTip}
    >
      <S.Icon onClick={handleClick} style={iconStyles}>
        {children}
      </S.Icon>
    </OverlayTrigger>
  ) : (
    <S.Icon style={iconStyles}>
      {children}
    </S.Icon>
  )
}

export default ReelerIconV2
