import React, { useEffect, useState } from "react"
import { InfoCard } from "../../../../components/commons/infocard/InfoCard"
import { Spinner } from "react-bootstrap"
import { getTotalNumberOfAssets } from "../../../../services/getTotalNumberOfAssets"

export const TotalAssets = () => {
  const [totalAssets, setTotalAssets] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    getTotalNumberOfAssets().then(res => {
      if (res?.data?.noOfAssets) {
        setTotalAssets(res?.data?.noOfAssets)
        setLoading(false)
      } else {
        setTotalAssets(0)
        setLoading(false)
      }
    })
  }, [])

  return loading ? (
    <InfoCard
      label={"Assets"}
      children={<Spinner size="sm" animation="border" />}
    />
  ) : (
    <InfoCard label={"Assets"} kpi={totalAssets} />
  )
}

export default TotalAssets
