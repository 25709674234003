import React from "react"
import { Link } from "react-router-dom"
import * as S from "../../../styles/styles"
import GuideTopicVideos from "./components/GuideTopicVideos"
import GuideTopicInfo from "./components/GuideTopicInfo"
import { Tab, Button } from "react-bootstrap"
import { selectSelectedTopic } from "../../../redux/Guides"
import { useAppSelector } from "../../../redux/hooks"

const GuideTopicPage: React.FC = () => {
  const selectedTopic = useAppSelector(selectSelectedTopic)

  return (
    <S.Container>
      <S.Header className="mt-0">
        <S.TitleContainer>
          {selectedTopic?.title
            ? `Edit Topic ${selectedTopic?.title}`
            : "Edit Topic"}
        </S.TitleContainer>
        <div>
          <Link to="/guides">
            <Button variant="secondary" className="me-2">
              Back
            </Button>
          </Link>
        </div>
      </S.Header>
      <S.Body>
        <S.ReelerTabs id="topic-tabs" defaultActiveKey="videos">
          <Tab eventKey="info" title="Topic info">
            <S.ReelerTab>
              <GuideTopicInfo />
            </S.ReelerTab>
          </Tab>
          <Tab eventKey="videos" title="Videos">
            <S.ReelerTab>
              <GuideTopicVideos />
            </S.ReelerTab>
          </Tab>
        </S.ReelerTabs>
      </S.Body>
    </S.Container>
  )
}

export default GuideTopicPage
