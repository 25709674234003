import React, { useState } from "react"
import Select, { MultiValue } from "react-select"
import { Form, Modal, ModalHeader, ModalTitle } from "react-bootstrap"
import ReelerButton from "../../../components/commons/ReelerButton"
import {
  addNewNotification,
  selectedNotification,
  setNotification,
  updateNotification,
} from "../../../redux/Notifications"
import { selectedAccounts } from "../../../redux/data"
import { useDispatch } from "react-redux"
import { Notification } from "../../../types/Notification"
import { useSelector } from "react-redux"
import { Customer } from "../../../types/Customers"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { useAlertUserMessage } from "../../../hooks/useAlertUserMessage"
import {
  createNewNotificationDB,
  updateNotificationDB,
} from "../../../services/NotificationService"
interface Option {
  value: string
  label: string
}
const CreateNewNotificationModal: React.FC = () => {
  const options: Option[] = [
    { value: "Ving", label: "Ving" },
    { value: "Penny lovers", label: "Penny lovers" },
    { value: "vanilla", label: "Vanilla" },
  ]
  const accounts = useAppSelector(selectedAccounts)
  const dispatch = useAppDispatch()
  const notification = useAppSelector(selectedNotification)
  const { setAlertMessage } = useAlertUserMessage()
  const handleClose = () => {
    dispatch(setNotification(null))
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    dispatch(
      setNotification({
        ...notification,
        [name]: value,
      } as Notification)
    )
  }

  // const handleVariants = (variant: string) => {
  //   dispatch(
  //     setNotification({
  //       ...notification,
  //       variant,
  //     } as Notification)
  //   )
  // }

  const handleSave = async () => {
    if (!notification) return

    if (!notification.id) {
      try {
        const newNotification = await createNewNotificationDB(notification)
        dispatch(addNewNotification(newNotification))
        setAlertMessage({
          message: "Successfully created new notification",
          variant: "success",
        })
      } catch (error) {
        console.log(error)
        setAlertMessage({
          message: "error",
          variant: "danger",
        })
      }
    } else {
      try {
        await updateNotificationDB(notification)
        dispatch(updateNotification(notification))
        setAlertMessage({
          message: "Successfully updated notification",
          variant: "success",
        })
      } catch (error) {
        console.log(error)
        setAlertMessage({
          message: "Oops something went wrong",
          variant: "danger",
        })
      }
    }

    handleClose()
  }

  const handleSelectChange = (value: readonly Option[]) => {
    console.log(value)
    const customers = value?.map(
      v => ({ id: v.value, name: v.label } as Customer)
    )
    dispatch(
      setNotification({
        ...notification,
        customers,
      } as Notification)
    )
  }

  return (
    <>
      <Modal show={notification ? true : false} onHide={handleClose}>
        <ModalHeader closeButton>
          <ModalTitle>
            {notification?.id ? "Edit Title" : "Create New Title"}
          </ModalTitle>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                name="title"
                value={notification?.title}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="message">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Message"
                name="message"
                value={notification?.message}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="variant">
              <Form.Label>Variant</Form.Label>
              <Form.Control
                as="select"
                name="variant"
                value={notification?.variant}
                onChange={handleChange}
              >
                <option value="info">Info</option>
                <option value="warning">Warning</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Customers</Form.Label>
              <Select
                options={accounts.map(account => {
                  return { value: account.id, label: account.name }
                })}
                isMulti
                onChange={handleSelectChange}
                value={notification?.customers?.map(
                  c => ({ value: c.id, label: c.name } as Option)
                )}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <ReelerButton
            dispatch={handleSave}
            text={notification?.id ? "Save" : "Create new notification"}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreateNewNotificationModal
