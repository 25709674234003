import React, { useState } from "react"
import { Navbar, Dropdown, Nav } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import * as S from "./navbar.styles"
import { Link, NavLink } from "react-router-dom"
import { logout } from "../../contexts/AuthFunctions"
import { updateDetails } from "../../redux/auth"
import { FaUserCircle } from "react-icons/fa"
import { updateData } from "../../redux/data"
import variables from "../../styles/variables"
import { ROUTES } from "../../constants/routeConstants"

const MainNavbar = () => {
  const dispatch = useAppDispatch()
  const [expanded, setExpanded] = useState<boolean>(false)
  const auth = useAppSelector(state => state.auth)

  const logoutRedux = () => {
    logout()
    dispatch(
      updateDetails({
        user: {
          id: "",
          role: "",
          email: "",
        },
        msg: "",
      })
    )
    dispatch(
      updateData({
        data: {
          accounts: [],
          campaigns: [],
          users: [],
          feeds: [],
          noOfAssetsLastMonth: 0,
          noOfAssetsYesterday: 0,
          kpis: [],
        },
      })
    )
  }

  return (
    <>
      <S.MainNavbarContainer>
        <Navbar
          className="nav_background p-0 ps-3"
          expand="sm"
          fixed="top"
          expanded={expanded}
          style={{ backgroundColor: variables.reelerBlack }}
        >
          <Navbar.Brand
            className="logo me-5"
            style={{
              fontSize: "30px",
            }}
            as={Link}
            to={ROUTES.dashboard}
          >
            Reeler
          </Navbar.Brand>
          <Navbar.Toggle
            className="me-2 navbar-dark"
            style={{ border: "0" }}
            aria-controls="responsive-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-between"
          >
            <Nav className="me-auto mb-2 mt-2 mw-25 align-items-center ">
              <NavLink
                to={ROUTES.dashboard}
                style={{ maxWidth: "80px" }}
                className={navData => {
                  const activeInfo = navData.isActive
                    ? "active-main-nav"
                    : "link"

                  return `ps-0 me-4 align-items-center ` + activeInfo
                }}
                onClick={() => setExpanded(false)}
              >
                <S.CustomNavLink>Home</S.CustomNavLink>
              </NavLink>
              <NavLink
                to={ROUTES.customer}
                className={navData => {
                  const activeInfo = navData.isActive
                    ? "active-main-nav"
                    : "link"
                  return `ps-0 me-4 ` + activeInfo
                }}
                onClick={() => setExpanded(false)}
              >
                <S.CustomNavLink>Customer</S.CustomNavLink>
              </NavLink>
              <NavLink
                to={ROUTES.GuideTopicsPage}
                className={navData => {
                  const activeInfo = navData.isActive
                    ? "active-main-nav"
                    : "link"
                  return `ps-0 me-4 ` + activeInfo
                }}
                onClick={() => setExpanded(false)}
              >
                <S.CustomNavLink>Guides</S.CustomNavLink>
              </NavLink>

              <NavLink
                to={ROUTES.CommunicationPage}
                className={navData => {
                  const activeInfo = navData.isActive
                    ? "active-main-nav"
                    : "link"
                  return `ps-0 me-4 ` + activeInfo
                }}
                onClick={() => setExpanded(false)}
              >
                <S.CustomNavLink>Communication</S.CustomNavLink>
              </NavLink>

            </Nav>
            <S.HideWhenSmallDevice>
              <Nav className="me-auto mb-2 mt-2">
                <Dropdown drop="down">
                  <Dropdown.Toggle
                    style={{ color: "white" }}
                    variant="whitegray"
                    id="dropdown-basic"
                  >
                    <FaUserCircle className="icon-color" size={30} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    variant="dark"
                    style={{ backgroundColor: variables.reelerBlack }}
                    align="end"
                    className="mt-3 me-1"
                  >
                    <Dropdown.Item>{auth.user.email}</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={() => {
                        logoutRedux()
                      }}
                    >
                      Log out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </S.HideWhenSmallDevice>
            <S.DisplayWhenSmallDevice>
              <Nav>
                <S.ProfileLink
                  to={"/"}
                  danger={"true"}
                  className="linkLogOut"
                  onClick={() => {
                    logoutRedux()
                  }}
                >
                  Log out
                </S.ProfileLink>
              </Nav>
            </S.DisplayWhenSmallDevice>
          </Navbar.Collapse>
        </Navbar>
      </S.MainNavbarContainer>
    </>
  )
}

export default MainNavbar
