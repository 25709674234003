import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks"
import { Button, Modal } from "react-bootstrap"
import {
  deleteVideoById,
  selectSelectedTopic,
  selectTopicToBeDeleted,
  selectTopicVideoToBeDeleted,
  setVideoToBeDeleted,
  updateTopicsWithSelectedTopic,
} from "../../../../redux/Guides"
import { deleteGuideTopicVideo } from "../../../../services/GuideServices"
import { showMessage } from "../../../../redux/alertUserMessage"

const DeleteGuideTopicVideo: React.FC = () => {
  const dispatch = useAppDispatch()
  const selectedTopic = useAppSelector(selectSelectedTopic)
  const topicVideoToBeDeleted = useAppSelector(selectTopicVideoToBeDeleted)
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    if (topicVideoToBeDeleted) {
      setShow(true)
    }
  }, [topicVideoToBeDeleted])

  const handleClose = () => {
    dispatch(setVideoToBeDeleted(null))
    setShow(false)
  }

  const handleDeleteClick = () => {
    if (!selectedTopic) return
    if (!topicVideoToBeDeleted) return

    deleteGuideTopicVideo(selectedTopic.id, topicVideoToBeDeleted.id)
      .then(() => {
        dispatch(deleteVideoById(topicVideoToBeDeleted.id))
        dispatch(updateTopicsWithSelectedTopic())
        handleClose()
        dispatch(showMessage({
          message:"Delete successfull",
          variant: "success",
        }))
      })
      .catch(err => console.log("Err", err))
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Want to delete {topicVideoToBeDeleted?.title}?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Button onClick={() => handleDeleteClick()} variant="danger">
          Delete
        </Button>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteGuideTopicVideo
