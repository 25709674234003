import React, { useEffect, useState } from "react";
import { InfoCard } from "../../../../components/commons/infocard/InfoCard";
import { selectData } from "../../../../redux/data";
import { useAppSelector } from "../../../../redux/hooks";

export const TotalAccounts = () => {
  const [accountsLength, setAccountsLength] = useState<number>(0);
  const { accounts } = useAppSelector(selectData);

  useEffect(() => {
    if (accounts) {
      setAccountsLength(accounts.length);
    }
  }, [accounts]);

  return <InfoCard label={"Accounts"} kpi={accountsLength} />;
};

export default TotalAccounts;
