import React, { ChangeEvent, useRef } from "react"
import { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks"
import * as S from "./Thumbnail.styles"
import { GuideVideo } from "../../../../../types/GuideVideo"
import {
  selectSelectedTopic,
  selectSelectedTopicVideo,
  setSelectedThumbnail,
  updateTopicVideo,
  updateTopicsWithSelectedTopic,
} from "../../../../../redux/Guides"
import { CgCloseO } from "react-icons/cg"
import {
  deleteThumbnail,
} from "../../../../../services/GuideServices"

function Thumbnail() {
  const dispatch = useAppDispatch()
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const selectedVideo = useAppSelector(selectSelectedTopicVideo)
  const selectedTopic = useAppSelector(selectSelectedTopic)
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setThumbnailUrl(URL.createObjectURL(file))
      dispatch(setSelectedThumbnail(file))
    }
  }

  const handleDeleteThumbnail = () => {
    if (selectedTopic && selectedVideo?.thumbnail) {
      deleteThumbnail(selectedTopic.id, selectedVideo).then(() => {
        console.log("deleted file!")
        let updatedVideo: GuideVideo = { ...selectedVideo }
        delete updatedVideo.thumbnail
        dispatch(updateTopicVideo(updatedVideo))
        dispatch(updateTopicsWithSelectedTopic())
        setThumbnailUrl(null)
      })
    } else {
      setThumbnailUrl(null)
      dispatch(setSelectedThumbnail(null))
      if (fileInputRef.current) {
        fileInputRef.current.value = ""
      }
    }
  }

  useEffect(() => {
    if (selectedVideo && selectedVideo?.thumbnail?.url) {
      setThumbnailUrl(selectedVideo.thumbnail.url)
    }
  }, [selectedVideo])

  return (
    <div className="d-flex flex-column mt-4 w-50">
      <Form.Label className="w-25">Thumbnail</Form.Label>
      <Form.Group className="mb-3" controlId="formFileSm">
        <Form.Control
          ref={fileInputRef}
          type="file"
          accept="image/jpeg, image/png"
          onChange={handleFileChange}
        />
      </Form.Group>
      {thumbnailUrl && (
        <S.ImageContainer className="d-flex flex-column position-relative">
          <S.DeleteButton onClick={() => handleDeleteThumbnail()}>
            <CgCloseO />
          </S.DeleteButton>
          <img src={thumbnailUrl} alt="Thumbnail" />
        </S.ImageContainer>
      )}
    </div>
  )
}

export default Thumbnail
