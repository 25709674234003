import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GuideTopic } from "../types/GuideTopic"
import { GuideVideo } from "../types/GuideVideo"
import { rootState } from "./store"

export interface guidesState {
  topics: GuideTopic[]
  selectedTopic: GuideTopic | null
  selectedTopicVideo: GuideVideo | null
  topicToBeDeleted: GuideTopic | null
  topicVideoToBeDeleted: GuideVideo | null
  selectedThumbnail: File | null
  videoToBeUploaded: File | null
}

// Initial state when starting application
const initialState: guidesState = {
  topics: [],
  selectedTopic: null,
  selectedTopicVideo: null,
  topicToBeDeleted: null,
  topicVideoToBeDeleted: null,
  selectedThumbnail: null,
  videoToBeUploaded: null,
}

// Alla funktioner för att uppdatera state, payload är nya värdet på det man vill uppdatera state med
const guideSlice = createSlice({
  name: "guides",
  initialState,
  reducers: {
    // Detta fungerar enbart genom toolkit
    loadTopics(state, { payload }: PayloadAction<GuideTopic[]>) {
      state.topics = payload
      state.selectedTopic = null
      state.selectedTopicVideo = null
      state.selectedThumbnail = null
      state.videoToBeUploaded = null
    },
    updateTopics(state, { payload }: PayloadAction<GuideTopic[]>) {
      state.topics = payload
    },
    createNewTopic(state, { payload }: PayloadAction<GuideTopic>) {
      state.topics = [...state.topics, payload]
    },
    selectTopicById(state, { payload }: PayloadAction<string>) {
      const selectedTopic =
        state.topics.find(topic => topic.id === payload) || null
      state.selectedTopic = selectedTopic
    },
    updateTopic(state, { payload }: PayloadAction<GuideTopic>) {
      state.selectedTopic = payload
    },
    updateTopicsWithSelectedTopic(state) {
      // Uppdatera topics med den uppdaterade topic
      state.topics = state?.topics?.map(topic =>
        topic?.id === state?.selectedTopic?.id ? state.selectedTopic : topic
      )
    },
    selectTopicVideoById(state, { payload }: PayloadAction<string>) {
      state.selectedTopicVideo =
        state.selectedTopic?.videos?.find(video => video.id === payload) || null
    },
    clearTopicVideo(state) {
      state.selectedTopicVideo = null
    },
    createNewTopicVideo(state, { payload }: PayloadAction<GuideVideo>) {
      // uppdatera selectedTopic
      state.selectedTopic = {
        ...state?.selectedTopic,
        videos: state?.selectedTopic?.videos
          ? [...state?.selectedTopic?.videos, payload]
          : [payload],
      } as GuideTopic
    },
    updateTopicVideo(state, { payload }: PayloadAction<GuideVideo>) {
      //updatera selectedTopicVideo
      state.selectedTopic = {
        ...state?.selectedTopic,
        videos: state?.selectedTopic?.videos?.map(video =>
          video.id === payload.id ? payload : video
        ),
      } as GuideTopic
      state.selectedTopicVideo = payload
    },
    setSelectedThumbnail(state, { payload }: PayloadAction<File | null>) {
      //updatera selectedTopicVideo
      state.selectedThumbnail = payload
    },
    setVideoToBeUploaded(state, { payload }: PayloadAction<File | null>) {
      //updatera selectedTopicVideo
      state.videoToBeUploaded = payload
    },
    setTopicToBeDeleted(state, { payload }: PayloadAction<GuideTopic | null>) {
      state.topicToBeDeleted = payload
    },

    setVideoToBeDeleted(state, { payload }: PayloadAction<GuideVideo | null>) {
      state.topicVideoToBeDeleted = payload
    },

    deleteTopicById(state, { payload }: PayloadAction<string>) {
      state.topics = state.topics.filter(topic => topic.id !== payload)
      if (state.selectedTopic?.id === payload) {
        state.selectedTopic = null
      }
    },
    deleteVideoById(state, { payload }: PayloadAction<string>) {
      //updatera selectedTopicVideo
      state.selectedTopic = {
        ...state?.selectedTopic,
        videos: state?.selectedTopic?.videos?.filter(
          video => video.id !== payload
        ),
      } as GuideTopic
    },
  },
})

// Exportera funktionerna för att uppdatera state så de är tillgängliga i komponenter
export const {
  loadTopics,
  updateTopics,
  createNewTopic,
  selectTopicById,
  updateTopic,
  selectTopicVideoById,
  updateTopicVideo,
  clearTopicVideo,
  updateTopicsWithSelectedTopic,
  createNewTopicVideo,
  deleteTopicById,
  deleteVideoById,
  setTopicToBeDeleted,
  setVideoToBeDeleted,
  setSelectedThumbnail,
  setVideoToBeUploaded,
} = guideSlice.actions

// Funktioer för att hämta information från state. Namnet bör börja med select så man vet att det är en selector funktion
export const selectTopics = (state: rootState) => state.guides.topics
export const selectVideoToBeUploaded = (state: rootState) =>
  state.guides.videoToBeUploaded
export const selectSelectedTopic = (state: rootState) =>
  state.guides.selectedTopic
export const selectSelectedTopicVideo = (state: rootState) =>
  state.guides.selectedTopicVideo
export const selectTopicToBeDeleted = (state: rootState) =>
  state.guides.topicToBeDeleted
export const selectTopicVideoToBeDeleted = (state: rootState) =>
  state.guides.topicVideoToBeDeleted
export const selectSelelectedThumbnail = (state: rootState) =>
  state.guides.selectedThumbnail

export default guideSlice.reducer
