import React from "react";
import variables from "../../../styles/variables";
import { Container, Alert } from "./StaticAlert.styles";

interface Istatic {
  success?: string;
  lead?: string;
  text?: string;
}

const StaticAlert = ({ success, lead, text }: Istatic) => {
  return (
    { success, lead, text } && (
      <Container>
        <Alert variant={success ? variables.reeler : variables.reelerDanger}>
          <strong>{lead ? lead : ""} </strong> {text}
        </Alert>
      </Container>
    )
  );
};

export default StaticAlert;
