import { IAccounts, ICampaigns, IFeeds } from "../redux/data";

export const sortAccountByName = (filtAcc: IAccounts[]) => {
  const tempAcc = [...filtAcc];

  tempAcc?.sort((a, b) => {
    if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
    if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
    else return 0;
  });

  return tempAcc;
};

export const sortCampaignByName = (filteredCampaigns: ICampaigns[]) => {
  const tempCamp = [...filteredCampaigns];

  tempCamp?.sort((a, b) => {
    if (a.campaignName.toUpperCase() < b.campaignName.toUpperCase()) return -1;
    if (a.campaignName.toUpperCase() > b.campaignName.toUpperCase()) return 1;
    else return 0;
  });

  return tempCamp;
};

export const sortAccountAssets = (filtAcc: IAccounts[]) => {
  const tempCamp = [...filtAcc];

  tempCamp.sort((a, b) => {
    return b.totalAssets! - a.totalAssets!;
  });

  return tempCamp;
};

export const sortCampaignAssets = (filteredCampaigns: ICampaigns[]) => {
  const tempCamp = [...filteredCampaigns];

  tempCamp.sort((a, b) => {
    return b.assets - a.assets;
  });

  return tempCamp;
};

export const sortAccountByDate = (filtAcc: IAccounts[]) => {
  const tempAcc = [...filtAcc];

  tempAcc.sort((a, b) => {
    return b.createdAt._seconds - a.createdAt._seconds;
  });
  return tempAcc;
};

export const sortCampaignByDate = (filteredCampaigns: ICampaigns[]) => {
  const tempCamp = [...filteredCampaigns];

  tempCamp.sort((a, b) => {
    return b.createdAt.seconds - a.createdAt.seconds;
  });
  return tempCamp;
};

export const sortFeedByName = (filteredFeeds: IFeeds[]) => {
  const tempCamp = [...filteredFeeds];

  tempCamp?.sort((a, b) => {
    if (a.feedName.toUpperCase() < b.feedName.toUpperCase()) return -1;
    if (a.feedName.toUpperCase() > b.feedName.toUpperCase()) return 1;
    else return 0;
  });
  return tempCamp;
};

export const sortFeedAssets = (filteredFeeds: IFeeds[]) => {
  const tempFeed = [...filteredFeeds];

  tempFeed.sort((a, b) => {
    return b.assetCount - a.assetCount;
  });
  return tempFeed;
};

export const sortFeedByDate = (filteredFeeds: IFeeds[]) => {
  const tempFeed = [...filteredFeeds];

  tempFeed.sort((a, b) => {
    return b.createdAt._seconds - a.createdAt._seconds;
  });

  return tempFeed;
};

export const sortTest = (array: any[], sortField: string) => {
  const tempCamp = [...array];

  tempCamp?.sort((a, b) => {
    if (a.sortField.toUpperCase() < b.sortField.toUpperCase()) return -1;
    if (a.sortField.toUpperCase() > b.sortField.toUpperCase()) return 1;
    else return 0;
  });
  return tempCamp;
};
