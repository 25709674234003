import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalTitle } from 'react-bootstrap'
import { FaTrash } from 'react-icons/fa';
import ReelerIconV2 from '../ReelerIconV2';

interface props {
  onDelete: () => void;
  title?: string | undefined;
  body?: React.ReactNode
}
const DeleteModal: React.FC<props> = ({ title, onDelete, body }) => {
  const [show, setShow] = useState<boolean>(false);

  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  const handleDelete = () => {
     onDelete()
     handleClose()
  }

  return (
    <>
      <ReelerIconV2
        tooltip='Delete?'
        onClick={handleShow}
      >
        <FaTrash size={15}  />
      </ReelerIconV2>
      <Modal show={show} onHide={handleClose}>
        <ModalHeader closeButton>
          <ModalTitle> {title}</ModalTitle>
        </ModalHeader>
        <Modal.Body>
           {body} 
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' 
          onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeleteModal
