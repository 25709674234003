import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

interface IlastAsset {
  accountId: string;
  campaignTypes: string[];
  data?: {
    error: {
      message: string;
    };
    createdAt: {
      _seconds: number;
      _nanoseconds: number;
    };
  };
}

export const getDateCampaignType = async (
  accountId: string,
  campaignTypes: string[]
): Promise<IlastAsset | undefined> => {
  const getData = httpsCallable<IlastAsset, undefined>(
    functions,
    "getDateForLastCreatedAssetPerAccountAndCampaignType"
  );

  try {
    const result = (await getData({
      accountId: accountId,
      campaignTypes: campaignTypes,
    })) as IlastAsset;
    return result;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};
